import { createEntityAdapter, EntityAdapter, EntityState } from 'redux-ngrx-entity';
import { v4 } from 'uuid';
import { Snackbar } from './models';
import { SnackbarActions, SnackbarActionTypes } from './snackbar.actions';

export const SNACKBAR_FEATURE_KEY = 'snackbars';

export interface SnackbarState extends EntityState<Snackbar> {}

export const adapter: EntityAdapter<Snackbar> = createEntityAdapter<Snackbar>();

export const initialState = adapter.getInitialState();

export function reducer(state = initialState, action: SnackbarActions) {
	switch (action.type) {
		case SnackbarActionTypes.Add: {
			const id = v4();
			return adapter.addOne({ id, ...action.snackbar }, state);
		}
		case SnackbarActionTypes.Hide: {
			return adapter.updateOne({ id: action.id, changes: { visible: false } }, state);
		}
		case SnackbarActionTypes.Remove: {
			return adapter.removeOne(action.id, state);
		}
		default:
			return state;
	}
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
