import { Organization } from '../../entities';
import { env } from '../../enviroment';
import { getHeaders } from '../../utils';

export namespace OrganizationService {
	export async function getAll(): Promise<Organization[]> {
		const response = await fetch(`${env.apiUrl}/api/1/Organization?showDisabled=true`, {
			headers: getHeaders(),
		});
		if (!response.ok) throw response;
		return await response.json();
	}

	export async function create(name: string) {
		const response = await fetch(`${env.apiUrl}/api/1/Organization/${name}`, {
			method: 'POST',
			headers: getHeaders({ 'Content-Type': 'application/json' }),
		});
		if (!response.ok) throw response;
		return await response.json();
	}

	export async function update(organization: Partial<Organization>) {
		const response = await fetch(`${env.apiUrl}/api/1/Organization`, {
			method: 'PATCH',
			headers: getHeaders({ 'Content-Type': 'application/json' }),
			body: JSON.stringify(organization),
		});
		if (!response.ok) throw response;
		return await response.json();
	}

	export async function deleteOrg(orgId: number, forceDelete: boolean = false) {
		const response = await fetch(
			`${env.apiUrl}/api/1/Organization/${orgId}${forceDelete ? '?forceDelete=true' : ''}`,
			{
				method: 'DELETE',
				headers: getHeaders({}),
			},
		);
		if (!response.ok) throw response;
	}
}
