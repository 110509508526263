import { Environment } from '../../entities';
import { env } from '../../enviroment';
import { getHeaders } from '../../utils';

export namespace EnvironmentService {
	export async function getAll(customerId?: number): Promise<Environment[]> {
		const response = await fetch(
			`${env.apiUrl}/api/1/environment?showDisabled=true&${
				customerId ? `CustomerId=${customerId}` : ''
			}`,
			{ headers: getHeaders({ 'Content-Type': 'application/json' }) },
		);
		if (!response.ok) throw response;
		return await response.json();
	}

	export async function get(enviromentId?: number): Promise<Environment> {
		const response = await fetch(
			`${env.apiUrl}/api/1/environment?showDisabled=true&${
				enviromentId ? `Id=${enviromentId}` : ''
			}`,
			{ headers: getHeaders({ 'Content-Type': 'application/json' }) },
		);
		if (!response.ok) throw response;
		const json = await response.json();
		return json[0];
	}

	export async function create(customerId: number, name: string): Promise<Environment> {
		const response = await fetch(`${env.apiUrl}/api/1/environment`, {
			headers: getHeaders({ 'Content-Type': 'application/json' }),
			body: JSON.stringify({ name, customerId }),
			method: 'POST',
		});
		if (!response.ok) throw response;
		return await response.json();
	}

	export async function update(enviroment: Partial<Environment>): Promise<Environment> {
		const response = await fetch(`${env.apiUrl}/api/1/environment`, {
			headers: getHeaders({ 'Content-Type': 'application/json' }),
			body: JSON.stringify(enviroment),
			method: 'PATCH',
		});
		if (!response.ok) throw response;
		return await response.json();
	}

	export async function deleteEnv(id: number) {
		const response = await fetch(`${env.apiUrl}/api/1/environment/${id}`, {
			headers: getHeaders({ 'Content-Type': 'application/json' }),
			method: 'DELETE',
		});
		if (!response.ok) throw response;
	}
}
