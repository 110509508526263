import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Chip, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridPaginationModel } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllCustomers, getCustomersLoaded, LoadCustomers } from '../../store/customer';
import { useHomeStyles } from '../../utils';
import { useRole } from '../RoleProvider';
import { Spacer } from '../Spacer';
import { useCustomerDialog } from './create';
import { useDeleteCustomerDialog } from './delete';

export function CustomerHome() {
	const nav = useNavigate();
	const { isAdmin, isPartnerAdmin } = useRole();
	const { classes } = useHomeStyles();
	const dispatch = useDispatch();
	const customers = useSelector(getAllCustomers);
	const customersLoaded = useSelector(getCustomersLoaded);
	const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
		pageSize: 5,
		page: 0,
	});

	const customerDialog = useCustomerDialog();
	const deleteCustomer = useDeleteCustomerDialog();

	useEffect(() => {
		if (!customersLoaded) {
			dispatch(new LoadCustomers());
		}
	}, [customersLoaded]);
	return (
		<>
			<Paper className={classes.container}>
				<div className={classes.title}>
					<Typography variant="h5" sx={{ flex: 1 }}>
						Customers
					</Typography>
					<div className={classes.title}>
						<Button variant="contained" onClick={() => customerDialog.open()}>
							{' '}
							<AddIcon />
							Create a new Customer
						</Button>
					</div>
				</div>
				<Spacer spacing={2} />
				<div>
					<DataGrid
						autoHeight
						loading={!customersLoaded}
						pageSizeOptions={[5, 10, 15, 25, 50]}
						paginationModel={paginationModel}
						onPaginationModelChange={setPaginationModel}
						disableRowSelectionOnClick
						onRowClick={params => {
							nav(`/customer/${params.row.id}`);
						}}
						getRowClassName={() => classes.pointer}
						columns={[
							{
								field: 'id',
								headerName: 'ID',
								editable: false,
							},
							{
								field: 'name',
								headerName: 'Name',
								editable: false,
							},
							{
								field: 'organizationName',
								headerName: 'Organization',
								editable: false,
								minWidth: 150,
							},
							{
								field: 'enabled',
								headerName: 'Enabled',
								editable: false,
								renderCell(params) {
									return params.row.enabled ? (
										<CheckIcon className={classes.icon} />
									) : (
										<CloseIcon className={classes.icon} />
									);
								},
							},
							{
								field: 'environmentCount',
								headerName: 'Environments',
								editable: false,
								flex: 1,
								renderCell(params) {
									if (params.row.environmentCount) {
										return (
											<Chip
												label={`${params.row.environmentCount} Environment${
													params.row.environmentCount! > 1 ? 's' : ''
												}`}
												color="primary"
												size="small"
											/>
										);
									}
									return <></>;
								},
							},
							{
								field: '',
								headerName: 'Actions',
								editable: false,
								hideable: false,
								renderCell(params) {
									return (
										<>
											<Tooltip title="Edit">
												<IconButton
													onClick={evt => {
														evt.stopPropagation();
														customerDialog.open(params.row);
													}}
													sx={{ zIndex: 30 }}
												>
													<EditIcon />
												</IconButton>
											</Tooltip>
											<Tooltip title={params.row.enabled ? 'Disable' : 'Delete'}>
												<IconButton
													disabled={!(isAdmin || isPartnerAdmin)}
													onClick={evt => {
														evt.stopPropagation();
														deleteCustomer.open(
															params.row,
															!params.row.enabled && (isAdmin || isPartnerAdmin),
														);
													}}
													sx={{ zIndex: 30 }}
												>
													<DeleteIcon />
												</IconButton>
											</Tooltip>
										</>
									);
								},
							},
						]}
						rows={customers}
					/>
				</div>
			</Paper>
			{customerDialog.render()}
			{deleteCustomer.render()}
		</>
	);
}
