import { Dialog, DialogTitle } from '@mui/material';
import { useState } from 'react';
import { License } from '../../entities';
import LicenseForm from './License';

export function useCreateLicenseDialog() {
	const [open, setOpen] = useState(false);
	const [license, setLicense] = useState<License>();

	const onClose = () => {
		setOpen(false);
		setLicense(undefined);
	};

	return {
		open(l?: License) {
			if (l) {
				setLicense(l);
			}
			setOpen(true);
		},
		render() {
			return (
				<Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
					<DialogTitle>Create a new License</DialogTitle>
					<LicenseForm onClose={onClose} existingLicense={license} />
				</Dialog>
			);
		},
	};
}
