import { ActionsObservable, combineEpics, ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { LicenseService } from '../../components/license';
import { addSnackbar } from '../snackbar';
import {
	CreateLicenceFail,
	CreateLicense,
	DeleteLicenceFail,
	DeleteLicense,
	LicenseActionTypes,
	LoadLicences,
	LoadLicencesFail,
	LoadLicencesSuccess,
	LoadSingleLicense,
	LoadSingleLicenseFail,
	LoadSingleLicenseSuccess,
} from './licenses.actions';

const loadLicenses$ = (action$: ActionsObservable<LoadLicences>) =>
	action$.pipe(
		ofType(LicenseActionTypes.LoadLicences),
		switchMap(action =>
			LicenseService.getAll(action.showDisabled).then(
				definitions => new LoadLicencesSuccess(definitions),
				err => new LoadLicencesFail(err),
			),
		),
	);

const loadSingleLicense$ = (action$: ActionsObservable<LoadSingleLicense>) =>
	action$.pipe(
		ofType(LicenseActionTypes.LoadSingleLicense),
		switchMap(action =>
			LicenseService.get(action.licenseId).then(
				definitions => new LoadSingleLicenseSuccess(definitions),
				err => new LoadSingleLicenseFail(err),
			),
		),
	);

const createLicense$ = (action$: ActionsObservable<CreateLicense>) =>
	action$.pipe(
		ofType<CreateLicense>(LicenseActionTypes.CreateLicense),
		switchMap(action =>
			LicenseService.create(action.license).then(
				_ => new LoadLicences(),
				err => new CreateLicenceFail(err),
			),
		),
	);

const createLicenseFail$ = (action$: ActionsObservable<CreateLicenceFail>) =>
	action$.pipe(
		ofType<CreateLicenceFail>(LicenseActionTypes.CreateLicenseFail),
		switchMap(async () => addSnackbar({ variant: 'error', message: 'Failed to create license' })),
	);

const deleteLicense$ = (action$: ActionsObservable<DeleteLicense>) =>
	action$.pipe(
		ofType<DeleteLicense>(LicenseActionTypes.DeleteLicense),
		switchMap(action => {
			if (!action.licenseId) {
				return Promise.resolve(new DeleteLicenceFail(new Error('licenseId not valid')));
			}
			return LicenseService.deleteLicense(action.licenseId!).then(
				_ => new LoadLicences(JSON.parse(localStorage.getItem('license:showDisabled') ?? 'false')),
				err => new DeleteLicenceFail(err),
			);
		}),
	);

const deleteLicenseFail$ = (action$: ActionsObservable<DeleteLicenceFail>) =>
	action$.pipe(
		ofType<DeleteLicenceFail>(LicenseActionTypes.DeleteLicenseFail),
		switchMap(async () => addSnackbar({ variant: 'error', message: 'Failed to delete license' })),
	);
export const effects$ = combineEpics(
	loadLicenses$,
	createLicense$,
	deleteLicense$,
	createLicenseFail$,
	deleteLicenseFail$,
	loadSingleLicense$,
);
