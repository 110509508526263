import { createEntityAdapter, EntityState } from 'redux-ngrx-entity';
import { Customer } from '../../entities';
import { CustomerActions, CustomerActionTypes } from './customer.actions';

export const CUSTOMER_FEAUTURE_KEY = 'customer';

export interface CustomerState extends EntityState<Customer> {
	loaded: boolean;
}

export const customerAdapter = createEntityAdapter<Customer>({
	selectId: entity => entity.id ?? 0,
});

const initialState: CustomerState = customerAdapter.getInitialState({
	loaded: false,
});

export function reducer(
	state: CustomerState = initialState,
	action: CustomerActions,
): CustomerState {
	switch (action.type) {
		case CustomerActionTypes.LoadCustomersSuccess: {
			return customerAdapter.upsertMany(action.customers, { ...state, loaded: true });
		}
		case CustomerActionTypes.LoadCustomersFail: {
			return { ...state, loaded: true };
		}
		case CustomerActionTypes.CreateCustomerSuccess: {
			return customerAdapter.addOne(action.customer, state);
		}

		case CustomerActionTypes.UpdateCustomerSuccess: {
			return customerAdapter.upsertOne(action.customer, state);
		}

		case CustomerActionTypes.DeleteCustomerSuccess: {
			return customerAdapter.removeOne(action.customerId, state);
		}
		case CustomerActionTypes.LoadSingleCustomerSuccess: {
			return customerAdapter.upsertOne(action.customer, state);
		}
		case '@toca/clear': {
			return customerAdapter.removeAll({ ...state, loaded: false });
		}
	}

	return state;
}
