import {
	FormControl,
	FormHelperText,
	InputLabel,
	InputLabelProps,
	OutlinedInput,
	OutlinedInputProps,
	Select,
} from '@mui/material';
import { SelectProps } from '@mui/material/Select';
import { ReactNode, useRef } from 'react';

export interface OutlinedSelectProps
	extends Pick<SelectProps, Exclude<keyof SelectProps, 'margin'>> {
	label: string;
	labelWidth?: number;
	margin?: 'none' | 'dense' | 'normal';
	size?: 'small' | 'medium';
	helperText?: ReactNode;
	labelProps?: InputLabelProps;
	notched?: boolean;
	controlClass?: string;
	notchedOutlineClass?: string;
	OutlinedInputProps?: OutlinedInputProps;
}

/**
 * A component to render a select field using the standard outlined styling
 * @param {string} label The label of the field
 * @param {any} value The value of the field
 * @param {Function} onChange Callback handling field value updates
 * @param {'dense'|'normal'|'none'|undefined} margin Add a margin to the field
 * @param {'small'|'medium'|undefined} size The size of the field
 * @param {boolean} fullWidth Render the field at full width
 * @returns {JSX.Element} The field component
 * @example ```tsx
 * import { OutlinedSelect } from '@tocabot/components';
 * import { MenuItem } from '@mui/material';
 * ...
 * return (
 *     <OutlinedSelect
 *         label="Method"
 *         value={method}
 *         onChange={evt => setMethod(evt.target.value)}
 *     >
 *         <MenuItem value="GET">Get</MenuItem>
 *         <MenuItem value="POST">Post</MenuItem>
 *         <MenuItem value="PUT">Put</MenuItem>
 *     </OutlinedSelect>
 * );
 * ```
 * @tocadocs appcomponent Components component
 */
export function OutlinedSelect({
	label,
	margin = 'none',
	size,
	fullWidth,
	helperText,
	error = false,
	labelProps,
	notched,
	controlClass,
	OutlinedInputProps,
	...props
}: OutlinedSelectProps) {
	const inputLabelRef = useRef<HTMLLabelElement | null>(null);

	return (
		<FormControl
			variant="outlined"
			margin={margin}
			fullWidth={fullWidth}
			error={error}
			size={size}
			className={controlClass}
		>
			<InputLabel ref={inputLabelRef} {...labelProps}>
				{label}
			</InputLabel>
			<Select
				variant="outlined"
				label={label}
				input={<OutlinedInput label={label} notched={notched} {...OutlinedInputProps} />}
				{...props}
			/>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
}
