import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';

interface RoleContext {
	role: string;
	setRole(r: string): void;
}

const RoleContext = createContext<RoleContext | undefined>(undefined);

export function RoleProvider({ children }: PropsWithChildren<{}>) {
	const [role, setRole] = useState('');
	const value = useMemo(() => ({ role, setRole }), [role]);
	return <RoleContext.Provider value={value}>{children}</RoleContext.Provider>;
}

export function useRole() {
	const context = useContext(RoleContext);
	if (!context) {
		throw new Error('Make sure you have wrapped it in a role provider');
	}
	const { role, setRole } = context;

	const isAdmin = useMemo(() => role === 'Admin', [role]);
	const isPartnerAdmin = useMemo(() => role === 'PartnerAdmin', [role]);
	const isPartner = useMemo(() => role === 'Partner', [role]);

	return { role, setRole, isAdmin, isPartnerAdmin, isPartner };
}
