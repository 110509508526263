import {
	Alert,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Organization } from '../../entities';
import { DeleteOrganization } from '../../store/organization';
import { Spacer } from '../Spacer';

export function useDeleteOrgDialog() {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [force, setForce] = useState(false);
	const [org, setOrg] = useState<Organization>();

	const close = () => {
		setOpen(false);
		setOrg(undefined);
	};

	const deleteOrg = useCallback(async () => {
		if (org?.id) {
			dispatch(new DeleteOrganization(org.id, force));
			close();
		}
	}, [org, force]);

	return {
		open(o: Organization, f: boolean = false) {
			setOpen(true);
			setForce(f);
			setOrg(o);
		},
		render() {
			const disableOrDelete = org?.enabled ? 'Disable' : 'Delete';
			return (
				<Dialog open={open} onClose={close}>
					<DialogTitle>
						{disableOrDelete} Organization <code>'{org?.name}'</code>
					</DialogTitle>
					<DialogContent>
						<Typography>
							You are about to {disableOrDelete.toLowerCase()} <code>'{org?.name}'</code>. This{' '}
							{disableOrDelete.toLowerCase()}s the organization from the platform and they will no
							longer be able to have licenses created for them.
						</Typography>
						{force && (
							<>
								<Spacer />
								<Alert severity="error">
									Deleting this organization will delete all customer, environments and licenses
									associated with this organization
								</Alert>
							</>
						)}
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setOpen(false)}>Cancel</Button>
						<Button onClick={deleteOrg}>{disableOrDelete}</Button>
					</DialogActions>
				</Dialog>
			);
		},
	};
}
