import { ActionsObservable, combineEpics, ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { EnvironmentService } from '../../components/environment';
import { LoadSingleCustomer } from '../customer';
import { addSnackbar } from '../snackbar';
import {
	CreateEnvironment,
	CreateEnvironmentFail,
	CreateEnvironmentSuccess,
	DeleteEnvironment,
	DeleteEnvironmentFail,
	DeleteEnvironmentSuccess,
	EnvironmentActionTypes,
	LoadEnvironments,
	LoadEnvironmentsFail,
	LoadEnvironmentsSuccess,
	LoadSingleEnvironment,
	LoadSingleEnvironmentSuccess,
	UpdateEnvironment,
	UpdateEnvironmentFail,
	UpdateEnvironmentSuccess,
} from './environment.actions';

const loadEnvironments$ = (action$: ActionsObservable<LoadEnvironments>) =>
	action$.pipe(
		ofType(EnvironmentActionTypes.LoadEnvironments),
		switchMap(action =>
			EnvironmentService.getAll(action.customerId).then(
				definitions => new LoadEnvironmentsSuccess(definitions, action.customerId!),
				err => new LoadEnvironmentsFail(err),
			),
		),
	);

const loadSingleEnvironment$ = (action$: ActionsObservable<LoadSingleEnvironment>) =>
	action$.pipe(
		ofType(EnvironmentActionTypes.LoadSingleEnvironment),
		switchMap(action =>
			EnvironmentService.get(action.environmentId).then(
				definition => new LoadSingleEnvironmentSuccess(definition),
				err => new LoadEnvironmentsFail(err),
			),
		),
	);

const createEnvironment$ = (action$: ActionsObservable<CreateEnvironment>) =>
	action$.pipe(
		ofType<CreateEnvironment>(EnvironmentActionTypes.CreateEnvironment),
		switchMap(action =>
			EnvironmentService.create(action.customerId, action.name).then(
				def => new CreateEnvironmentSuccess(def),
				err => new CreateEnvironmentFail(err),
			),
		),
	);

const updateEnvironment$ = (action$: ActionsObservable<UpdateEnvironment>) =>
	action$.pipe(
		ofType<UpdateEnvironment>(EnvironmentActionTypes.UpdateEnvironment),
		switchMap(action =>
			EnvironmentService.update(action.environment).then(
				def => new UpdateEnvironmentSuccess(def),
				err => new UpdateEnvironmentFail(err),
			),
		),
	);

const updateEnvironmentFail$ = (action$: ActionsObservable<UpdateEnvironmentFail>) =>
	action$.pipe(
		ofType<UpdateEnvironmentFail>(EnvironmentActionTypes.UpdateEnvironmentFail),
		switchMap(async () =>
			addSnackbar({ variant: 'error', message: 'Failed to update environment' }),
		),
	);

const updateCustomer$ = (action$: ActionsObservable<CreateEnvironmentSuccess>) =>
	action$.pipe(
		ofType<CreateEnvironmentSuccess>(EnvironmentActionTypes.CreateEnvironmentSuccess),
		switchMap(async action => new LoadSingleCustomer(action.environment.customerId)),
	);

const createEnvironmentFail$ = (action$: ActionsObservable<CreateEnvironmentFail>) =>
	action$.pipe(
		ofType<CreateEnvironmentFail>(EnvironmentActionTypes.CreateEnvironmentFail),
		switchMap(async () =>
			addSnackbar({ variant: 'error', message: 'Failed to create environment' }),
		),
	);

const deleteEnvironment$ = (action$: ActionsObservable<DeleteEnvironment>) =>
	action$.pipe(
		ofType<DeleteEnvironment>(EnvironmentActionTypes.DeleteEnvironment),
		switchMap(action =>
			EnvironmentService.deleteEnv(action.environmentId).then(
				_ =>
					!action.force
						? new LoadSingleEnvironment(action.environmentId)
						: new DeleteEnvironmentSuccess(action.environmentId),
				err => new DeleteEnvironmentFail(err),
			),
		),
	);

const deleteEnvironmentFail$ = (action$: ActionsObservable<DeleteEnvironmentFail>) =>
	action$.pipe(
		ofType<DeleteEnvironmentFail>(EnvironmentActionTypes.DeleteEnvironmentFail),
		switchMap(async () =>
			addSnackbar({ variant: 'error', message: 'Failed to delete environment' }),
		),
	);

export const effects$ = combineEpics(
	loadEnvironments$,
	createEnvironment$,
	deleteEnvironment$,
	createEnvironmentFail$,
	deleteEnvironmentFail$,
	updateCustomer$,
	loadSingleEnvironment$,
	updateEnvironment$,
	updateEnvironmentFail$,
);
