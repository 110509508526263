import { createSelector } from 'reselect';

export function createFeatureSelector<T, S = Record<string, any>>(featureName: keyof S) {
	return createSelector(
		(state: any) => {
			const featureState = state[featureName];
			assert(featureState !== undefined, `Missing feature state for: ${featureName as string}`);
			return featureState;
		},
		(featureState: T) => featureState,
	);
}

function assert(condition: boolean, message: string) {
	if (!condition) {
		throw new Error(message);
	}
}
