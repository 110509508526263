import { createEntityAdapter, EntityState } from 'redux-ngrx-entity';
import { License } from '../../entities';
import { LicenseActions, LicenseActionTypes } from './licenses.actions';

export const LICENSE_FEAUTURE_KEY = 'license';

export interface LicenseState extends EntityState<License> {
	loaded: boolean;
}

export const licenseAdapter = createEntityAdapter<License>({
	selectId: entity => entity.id ?? 0,
});

const initialState: LicenseState = licenseAdapter.getInitialState({
	loaded: false,
});

export function reducer(state: LicenseState = initialState, action: LicenseActions): LicenseState {
	switch (action.type) {
		case LicenseActionTypes.LoadLicencesSuccess: {
			// Reset state everytime we load all licenses
			return licenseAdapter.addMany(action.licences, { ...initialState, loaded: true });
		}
		case LicenseActionTypes.LoadLicencesFail: {
			return { ...state, loaded: true };
		}
		case LicenseActionTypes.CreateLicenseSuccess: {
			if (action.licence) {
				return licenseAdapter.addOne(action.licence, state);
			}
			return state;
		}
		case LicenseActionTypes.DeleteLicenseSuccess: {
			return licenseAdapter.removeOne(action.licenseId!, state);
		}
		case LicenseActionTypes.LoadSingleLicenseSuccess: {
			return licenseAdapter.upsertOne(action.license, state);
		}
		case '@toca/clear': {
			return licenseAdapter.removeAll({ ...state, loaded: false });
		}
	}

	return state;
}
