import { Action } from 'redux';
import { User } from '../../entities';
import { Clear } from '../clear';

export enum UserActionTypes {
	LoadUsers = '[User] Load Users',
	LoadUsersSuccess = '[User] Load Users Success',
	LoadUsersFail = '[User] Load Users Fail',

	CreateUser = '[User] Create User',
	CreateUserSuccess = '[User] Create User Success',
	CreateUserFail = '[User] Create User Fail',

	UpdateUser = '[User] Update User',
	UpdateUserSuccess = '[User] Update User Success',
	UpdateUserFail = '[User] Update User Fail',

	DeleteUser = '[User] Delete User',
	DeleteUserSuccess = '[User] Delete User Success',
	DeleteUserFail = '[User] Delete User Fail',
}

export class LoadUsers implements Action {
	public readonly type = UserActionTypes.LoadUsers;
	constructor() {}
}

export class LoadUsersSuccess implements Action {
	public readonly type = UserActionTypes.LoadUsersSuccess;
	constructor(public users: User[]) {}
}

export class LoadUsersFail implements Action {
	public readonly type = UserActionTypes.LoadUsersFail;
	constructor(public error: any) {}
}

export class CreateUser implements Action {
	public readonly type = UserActionTypes.CreateUser;
	constructor(public user: Partial<User>) {}
}

export class CreateUserSuccess implements Action {
	public readonly type = UserActionTypes.CreateUserSuccess;
	constructor(public user: User) {}
}

export class CreateUserFail implements Action {
	public readonly type = UserActionTypes.CreateUserFail;
	constructor(public error: any) {}
}

export class UpdateUser implements Action {
	public readonly type = UserActionTypes.UpdateUser;
	constructor(public user: Partial<User>) {}
}

export class UpdateUserSuccess implements Action {
	public readonly type = UserActionTypes.UpdateUserSuccess;
	constructor(public user: User) {}
}

export class UpdateUserFail implements Action {
	public readonly type = UserActionTypes.UpdateUserFail;
	constructor(public error: any) {}
}

export class DeleteUser implements Action {
	public readonly type = UserActionTypes.DeleteUser;
	constructor(public userId: number) {}
}

export class DeleteUserSuccess implements Action {
	public readonly type = UserActionTypes.DeleteUserSuccess;
	constructor(public userId: number) {}
}

export class DeleteUserFail implements Action {
	public readonly type = UserActionTypes.DeleteUserFail;
	constructor(public error: any) {}
}

export type UserActions =
	| LoadUsers
	| LoadUsersSuccess
	| LoadUsersFail
	| CreateUser
	| CreateUserSuccess
	| CreateUserFail
	| UpdateUser
	| UpdateUserSuccess
	| UpdateUserFail
	| DeleteUser
	| DeleteUserSuccess
	| DeleteUserFail
	| Clear;
