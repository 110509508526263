import { sortBy } from 'lodash';
import { Usage } from '../entities';
/**
 * Convert dateTime into an actual datetime
 * @param usage Usages
 * @returns
 */
export function transformUsage(usage: Usage[]): Usage[] {
	return usage.map(u => ({
		...u,
		usages: sortBy(
			u.usages.map(x => ({
				...x,
				dateTime: typeof x.dateTime === 'string' ? new Date(x.dateTime as string) : x.dateTime,
			})),
			x => x.dateTime,
		),
	}));
}
