import { createSelector } from 'reselect';
import { createFeatureSelector } from '../../utils/createFeatureSelctor';
import { USER_FEAUTURE_KEY, userAdapter, UserState } from './user.reducer';

const getUserFeatureState = createFeatureSelector<UserState>(USER_FEAUTURE_KEY);

const { selectAll, selectEntities } = userAdapter.getSelectors();

export const getAllUsers = createSelector(getUserFeatureState, selectAll);
export const getUserEntities = createSelector(getUserFeatureState, selectEntities);

export const getUserLoaded = createSelector(getUserFeatureState, state => state.loaded);
