import { createSelector } from 'reselect';
import { createFeatureSelector } from '../../utils/createFeatureSelctor';
import {
	ORGANIZATION_FEAUTURE_KEY,
	organizationAdapter,
	OrganizationState,
} from './organization.reducer';

const getOrganisationFeatureState =
	createFeatureSelector<OrganizationState>(ORGANIZATION_FEAUTURE_KEY);

const { selectAll, selectEntities } = organizationAdapter.getSelectors();

export const getAllOrgs = createSelector(getOrganisationFeatureState, selectAll);
export const getOrganisationEntities = createSelector(getOrganisationFeatureState, selectEntities);

export const getOrganizationLoaded = createSelector(
	getOrganisationFeatureState,
	state => state.loaded,
);
