import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	MenuItem,
	TextField,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Customer } from '../../entities';
import { CreateCustomer, UpdateCustomer } from '../../store/customer';
import { getAllOrgs, getOrganizationLoaded, LoadOrganizations } from '../../store/organization';
import { OutlinedSelect } from '../OutlinedSelect';
import { useRole } from '../RoleProvider';

interface CustomerDialogProps {
	open: boolean;
	onClose(): void;
	customer?: Customer;
}

function CustomerDialog({ open, onClose, customer }: CustomerDialogProps) {
	const dispatch = useDispatch();
	const { isAdmin, isPartnerAdmin } = useRole();
	const [name, setName] = useState('');
	const [organization, setOrganization] = useState<number | null>(null);
	const [enabled, setEnabled] = useState(true);
	const [errors, setErrors] = useState({});
	const allOrgs = useSelector(getAllOrgs);
	const orgsLoaded = useSelector(getOrganizationLoaded);
	const enabledOrgs = useMemo(() => allOrgs.filter(org => org.enabled), [allOrgs]);

	const close = () => {
		setName('');
		setOrganization(null);
		setErrors({});
		onClose();
	};

	useEffect(() => {
		if (!orgsLoaded) {
			dispatch(new LoadOrganizations());
		}
	}, [orgsLoaded]);

	const isEditing = useMemo(() => !!customer, [customer]);

	const submit = useCallback(() => {
		if (isEditing) {
			updateCustomer();
		} else {
			createCustomer();
		}
	}, [isEditing, customer, organization, name, enabled]);

	useEffect(() => {
		if (open && !!customer) {
			setName(customer.name);
			setOrganization(customer.organizationId);
			setEnabled(customer.enabled);
		}
	}, [open, customer]);

	const updateCustomer = useCallback(async () => {
		dispatch(new UpdateCustomer({ ...customer, name, enabled }));
		onClose();
	}, [name, organization, enabled, customer]);

	const createCustomer = useCallback(async () => {
		if (organization) {
			dispatch(new CreateCustomer(name, organization));
			onClose();
		} else {
			setErrors({ organization: 'An organization is needed to create a customer' });
		}
	}, [name, organization]);

	return (
		<Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
			<DialogTitle>{isEditing ? 'Edit' : 'Create'} Customer</DialogTitle>
			<DialogContent>
				<TextField
					value={name}
					label="Name"
					onChange={evt => setName(evt.target.value)}
					variant="outlined"
					fullWidth
					margin="dense"
				/>
				<OutlinedSelect
					label="Organization"
					disabled={!isAdmin}
					value={organization}
					fullWidth
					notched={!!organization}
					labelProps={{ shrink: !!organization }}
					onChange={evt => setOrganization(evt.target.value as number)}
					margin="dense"
					error={errors['organization']}
					helperText={errors['organization']}
				>
					{enabledOrgs.map(org => (
						<MenuItem key={org.id} value={org.id}>
							{org.name}
						</MenuItem>
					))}
				</OutlinedSelect>
				{isEditing && (
					<FormControlLabel
						label="Enabled"
						control={
							<Checkbox checked={enabled} onChange={evt => setEnabled(evt.target.checked)} />
						}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={close} color="inherit">
					Cancel
				</Button>
				<Button onClick={submit}>{isEditing ? 'Update' : 'Create'}</Button>
			</DialogActions>
		</Dialog>
	);
}

export function useCustomerDialog() {
	const [open, setOpen] = useState(false);
	const [customer, setCustomer] = useState<Customer>();
	return {
		open(c?: Customer) {
			setOpen(true);
			if (!!c) {
				setCustomer(c);
			}
		},
		render() {
			return <CustomerDialog open={open} onClose={() => setOpen(false)} customer={customer} />;
		},
	};
}
