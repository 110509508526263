import { Action } from 'redux';
import { Customer } from '../../entities';
import { Clear } from '../clear';

export enum CustomerActionTypes {
	LoadCustomers = '[Customer] Load Customers',
	LoadCustomersSuccess = '[Customer] Load Customers Success',
	LoadCustomersFail = '[Customer] Load Customers Fail',

	LoadSingleCustomer = '[Customer] Load single Customer',
	LoadSingleCustomerSuccess = '[Customer] Load single Customer Success',
	LoadSingleCustomerFail = '[Customer] Load single Customer Fail',

	CreateCustomer = '[Customer] Create Customer',
	CreateCustomerSuccess = '[Customer] Create Customer Success',
	CreateCustomerFail = '[Customer] Create Customer Fail',

	UpdateCustomer = '[Customer] Update Customer',
	UpdateCustomerSuccess = '[Customer] Update Customer Success',
	UpdateCustomerFail = '[Customer] Update Customer Fail',

	DeleteCustomer = '[Customer] Delete Customer',
	DeleteCustomerSuccess = '[Customer] Delete Customer Success',
	DeleteCustomerFail = '[Customer] Delete Customer Fail',
}

export class LoadCustomers implements Action {
	public readonly type = CustomerActionTypes.LoadCustomers;
	constructor(public customer?: string) {}
}

export class LoadCustomersSuccess implements Action {
	public readonly type = CustomerActionTypes.LoadCustomersSuccess;
	constructor(public customers: Customer[]) {}
}

export class LoadCustomersFail implements Action {
	public readonly type = CustomerActionTypes.LoadCustomersFail;
	constructor(public error: any) {}
}

export class LoadSingleCustomer implements Action {
	public readonly type = CustomerActionTypes.LoadSingleCustomer;
	constructor(public customerId: number) {}
}

export class LoadSingleCustomerSuccess implements Action {
	public readonly type = CustomerActionTypes.LoadSingleCustomerSuccess;
	constructor(public customer: Customer) {}
}

export class LoadSingleCustomerFail implements Action {
	public readonly type = CustomerActionTypes.LoadSingleCustomerFail;
	constructor(public error: any) {}
}
export class CreateCustomer implements Action {
	public readonly type = CustomerActionTypes.CreateCustomer;
	constructor(public name: string, public orgId: number) {}
}

export class CreateCustomerSuccess implements Action {
	public readonly type = CustomerActionTypes.CreateCustomerSuccess;
	constructor(public customer: Customer) {}
}

export class CreateCustomerFail implements Action {
	public readonly type = CustomerActionTypes.CreateCustomerFail;
	constructor(public error: any) {}
}

export class UpdateCustomer implements Action {
	public readonly type = CustomerActionTypes.UpdateCustomer;
	constructor(public customer: Partial<Customer>) {}
}

export class UpdateCustomerSuccess implements Action {
	public readonly type = CustomerActionTypes.UpdateCustomerSuccess;
	constructor(public customer: Customer) {}
}

export class UpdateCustomerFail implements Action {
	public readonly type = CustomerActionTypes.UpdateCustomerFail;
	constructor(public error: any) {}
}

export class DeleteCustomer implements Action {
	public readonly type = CustomerActionTypes.DeleteCustomer;
	constructor(public customerId: number, public force: boolean = false) {}
}

export class DeleteCustomerSuccess implements Action {
	public readonly type = CustomerActionTypes.DeleteCustomerSuccess;
	constructor(public customerId: number) {}
}

export class DeleteCustomerFail implements Action {
	public readonly type = CustomerActionTypes.DeleteCustomerFail;
	constructor(public error: any) {}
}

export type CustomerActions =
	| LoadCustomers
	| LoadCustomersSuccess
	| LoadSingleCustomer
	| LoadSingleCustomerSuccess
	| LoadSingleCustomerFail
	| LoadCustomersFail
	| CreateCustomer
	| CreateCustomerSuccess
	| CreateCustomerFail
	| UpdateCustomer
	| UpdateCustomerSuccess
	| UpdateCustomerFail
	| DeleteCustomer
	| DeleteCustomerSuccess
	| DeleteCustomerFail
	| Clear;
