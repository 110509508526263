import { getAccessToken } from './token';

export function getHeaders(init?: Record<string, string>, skipToken?: boolean) {
	const headers = new Headers(init);
	const token = getAccessToken();
	if (token && !skipToken) {
		headers.set('Authorization', `Bearer ${token}`);
	}

	return headers;
}
