import { Action } from 'redux';
import { Organization } from '../../entities';
import { Clear } from '../clear';

export enum OrganizationActionTypes {
	LoadOrganizations = '[Organization] Load Organizations',
	LoadOrganizationsSuccess = '[Organization] Load Organizations Success',
	LoadOrganizationsFail = '[Organization] Load Organizations Fail',

	CreateOrganization = '[Organization] Create Organization',
	CreateOrganizationSuccess = '[Organization] Create Organization Success',
	CreateOrganizationFail = '[Organization] Create Organization Fail',

	UpdateOrganization = '[Organization] Update Organization',
	UpdateOrganizationSuccess = '[Organization] Update Organization Success',
	UpdateOrganizationFail = '[Organization] Update Organization Fail',

	DeleteOrganization = '[Organization] Delete Organization',
	DeleteOrganizationSuccess = '[Organization] Delete Organization Success',
	DeleteOrganizationFail = '[Organization] Delete Organization Fail',
}

export class LoadOrganizations implements Action {
	public readonly type = OrganizationActionTypes.LoadOrganizations;
	constructor() {}
}

export class LoadOrganizationsSuccess implements Action {
	public readonly type = OrganizationActionTypes.LoadOrganizationsSuccess;
	constructor(public organizations: Organization[]) {}
}

export class LoadOrganizationsFail implements Action {
	public readonly type = OrganizationActionTypes.LoadOrganizationsFail;
	constructor(public error: any) {}
}

export class CreateOrganization implements Action {
	public readonly type = OrganizationActionTypes.CreateOrganization;
	constructor(public name: string) {}
}

export class CreateOrganizationSuccess implements Action {
	public readonly type = OrganizationActionTypes.CreateOrganizationSuccess;
	constructor(public organization: Organization) {}
}

export class CreateOrganizationFail implements Action {
	public readonly type = OrganizationActionTypes.CreateOrganizationFail;
	constructor(public error: any) {}
}

export class UpdateOrganization implements Action {
	public readonly type = OrganizationActionTypes.UpdateOrganization;
	constructor(public organization: Partial<Organization>) {}
}

export class UpdateOrganizationSuccess implements Action {
	public readonly type = OrganizationActionTypes.UpdateOrganizationSuccess;
	constructor(public organization: Organization) {}
}

export class UpdateOrganizationFail implements Action {
	public readonly type = OrganizationActionTypes.UpdateOrganizationFail;
	constructor(public error: any) {}
}

export class DeleteOrganization implements Action {
	public readonly type = OrganizationActionTypes.DeleteOrganization;
	constructor(public orgId: number, public force: boolean = false) {}
}

export class DeleteOrganizationSuccess implements Action {
	public readonly type = OrganizationActionTypes.DeleteOrganizationSuccess;
	constructor(public orgId: number) {}
}

export class DeleteOrganizationFail implements Action {
	public readonly type = OrganizationActionTypes.DeleteOrganizationFail;
	constructor(public error: any) {}
}

export type OrganizationActions =
	| LoadOrganizations
	| LoadOrganizationsSuccess
	| LoadOrganizationsFail
	| CreateOrganization
	| CreateOrganizationSuccess
	| CreateOrganizationFail
	| UpdateOrganization
	| UpdateOrganizationSuccess
	| UpdateOrganizationFail
	| DeleteOrganization
	| DeleteOrganizationSuccess
	| DeleteOrganizationFail
	| Clear;
