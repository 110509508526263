export function queryBuilder(options: Record<string, any | undefined>) {
	return Object.entries(options)
		.reduce((params, [key, value]) => {
			if (Array.isArray(value)) {
				value.forEach(v => {
					params.append(key, v);
				});
			}
			if (value) {
				params.append(key, value.toString());
			}
			return params;
		}, new URLSearchParams())
		.toString();
}
