import passwordValidator from 'password-validator';
import { PasswordSettings } from '../components/PasswordValidator';

export interface PasswordValitdation {
	minLength: number;
	maxLength: number;
	uppercase?: boolean;
	lowercase?: boolean;
	digits?: number;
	symbols?: number;
	blocklist?: string[] | null;
}

export function generatePasswordSchema(schema: PasswordValitdation) {
	const passwordSchema = new passwordValidator();
	passwordSchema.is().min(schema.minLength);
	passwordSchema.is().max(schema.maxLength);
	if (schema.uppercase) {
		passwordSchema.has().uppercase();
	}
	if (schema.lowercase) {
		passwordSchema.has().lowercase();
	}
	if (schema.digits) {
		passwordSchema.has().digits(schema.digits);
	}
	if (schema.symbols) {
		passwordSchema.has().symbols(schema.symbols);
	}
	if (schema.blocklist) {
		passwordSchema.is().not().oneOf(schema.blocklist);
	}

	return passwordSchema;
}

export function getPasswordErrors(
	password: string,
	schema: passwordValidator,
	rules: PasswordSettings,
): string {
	const errors = schema.validate(password, { list: true });
	switch (errors[0]) {
		case 'min':
			return `Password needs to be ${rules.minLength} characters long`;
		case 'max':
			return `Password cannot be over ${rules.minLength} characters long`;
		case 'uppercase':
			return 'Password needs to have at least one uppercase character';
		case 'lowercase':
			return 'Password needs to have at least one lowercase character';
		case 'symbols':
			return `Password needs to include at least ${rules.symbols} symbol(s)`;
		case 'digits':
			return `Password needs to include at least ${rules.digits} digit(s)`;
		default:
			return 'Password is invalid';
	}
}
