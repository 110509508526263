import { Alert, Button, CircularProgress, Slide, Snackbar } from '@mui/material';
import { cloneElement, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	hideSnackbar,
	removeSnackbar,
	selectSnackbars,
	Snackbar as SnackbarEntity,
} from '../store/snackbar';

export function SnackbarOutlet() {
	const snackbars = useSelector(selectSnackbars);
	const dispatch = useDispatch();
	const hide = (sb: SnackbarEntity) => {
		if (sb.id) {
			dispatch(hideSnackbar(sb.id));
			if (sb.onClose) {
				sb.onClose(sb.id);
			}
		}
	};
	const remove = (id: string) => dispatch(removeSnackbar(id));
	const snackbar = useMemo(() => snackbars[0], [snackbars]);

	return (
		<>
			{snackbar && (
				<SnackbarWrapper
					snackbar={snackbar}
					key={snackbar.id}
					onHide={() => hide(snackbar)}
					onExited={() => snackbar.id && remove(snackbar.id)}
				/>
			)}
		</>
	);
}

interface SnackbarWrapperProps {
	snackbar: SnackbarEntity;
	onHide?: () => void;
	onExited?: () => void;
}

function SnackbarWrapper({
	snackbar: { visible, message, duration, action: customAction, onAction, buttonText, variant },
	onHide = () => {},
	onExited = () => {},
}: SnackbarWrapperProps) {
	const [timeout, setTimeout] = useState<number | undefined>(undefined);

	const action = useMemo(() => {
		return duration
			? undefined
			: customAction
			? [cloneElement(customAction, { key: 'dismiss' })]
			: [
					<Button
						key="dismiss"
						color="inherit"
						size="small"
						onClick={() => {
							onHide();
							if (onAction) {
								onAction();
							}
						}}
					>
						{buttonText || 'Okay'}
					</Button>,
			  ];
	}, [duration, onHide, onAction, buttonText, customAction]);

	if (variant === 'loading') {
		return (
			<Snackbar
				open={visible}
				TransitionComponent={Slide}
				TransitionProps={{ onExited }}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert
					variant="filled"
					icon={<CircularProgress size={18} color="inherit" />}
					severity="info"
					elevation={8}
				>
					{message}
				</Alert>
			</Snackbar>
		);
	}

	if (variant) {
		return (
			<Snackbar
				open={visible}
				autoHideDuration={timeout}
				TransitionComponent={Slide}
				onClose={() => onHide()}
				TransitionProps={{ onExited, onEntered: () => setTimeout(duration) }}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert
					variant="filled"
					onClose={duration ? undefined : () => onHide()}
					severity={variant}
					elevation={8}
					action={action}
				>
					{message}
				</Alert>
			</Snackbar>
		);
	}

	return (
		<Snackbar
			open={visible}
			message={message}
			autoHideDuration={timeout}
			TransitionComponent={Slide}
			action={action}
			onClose={() => onHide()}
			TransitionProps={{ onExited, onEntered: () => setTimeout(duration) }}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
		/>
	);
}
