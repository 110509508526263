import { isNumber } from 'lodash';

const transform = (claims: any) => {
	const { exp, nbf, iat, ...rest } = claims;
	return {
		role: rest['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],
		exp: isNumber(exp) ? new Date(exp * 1000) : new Date(''),
		nbf: isNumber(nbf) ? new Date(nbf * 1000) : new Date(''),
		iat: isNumber(iat) ? new Date(iat * 1000) : new Date(''),
		...rest,
	};
};

export function getTokenExpirationDate(token: string): Date | null {
	let decoded: any;
	decoded = decodeToken(token);

	if (!decoded || !decoded.hasOwnProperty('exp')) {
		return null;
	}

	const date = new Date(0);
	date.setUTCSeconds(decoded.exp);

	return date;
}

export function isTokenExpired(token: string | null | undefined, offsetSeconds?: number) {
	if (token == null || token === '') {
		return true;
	}
	let date = getTokenExpirationDate(token);
	offsetSeconds = offsetSeconds || 0;

	if (date === null) {
		return false;
	}

	return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
}

export function getAccessToken() {
	try {
		return localStorage.getItem('access_token');
	} catch {
		return null;
	}
}

export function decodeToken(token: string): any {
	if (token == null || token === '') {
		return null;
	}

	let parts = token.split('.');

	if (parts.length !== 3) {
		throw new Error(
			"The inspected token doesn't appear to be a JWT. Check to make sure it has three parts and see https://jwt.io for more.",
		);
	}

	let decoded = urlBase64Decode(parts[1]);
	if (!decoded) {
		throw new Error('Cannot decode the token.');
	}

	return JSON.parse(decoded);
}

export function getClaims(token: string): any {
	return transform(decodeToken(token));
}

export type TokenClaims = {
	exp: Date;
	nbf: Date;
	iat: Date;
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name': string;
	unique_name: string;
	AssociatedUserId: string;
	role: string[] | string;
	provider?: string;
};

export function getTokenLifetime(token: string): number {
	const claims = getClaims(token);
	return claims.exp.getTime() - claims.iat.getTime();
}

export function getTokenName(): string {
	const token = getAccessToken();
	if (token) {
		const claims = getClaims(token);
		return claims['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
	}
	return '';
}

function urlBase64Decode(str: string): string {
	let output = str.replace(/-/g, '+').replace(/_/g, '/');
	switch (output.length % 4) {
		case 0: {
			break;
		}
		case 2: {
			output += '==';
			break;
		}
		case 3: {
			output += '=';
			break;
		}
		default: {
			throw 'Illegal base64url string!';
		}
	}
	return b64DecodeUnicode(output);
}

// credits for decoder goes to https://github.com/atk
function b64decode(str: string): string {
	let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
	let output: string = '';

	str = String(str).replace(/=+$/, '');

	if (str.length % 4 === 1) {
		throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
	}

	for (
		// initialize result and counters
		let bc: number = 0, bs: any, buffer: any, idx: number = 0;
		// get next character
		(buffer = str.charAt(idx++));
		// character found in table? initialize bit storage and add its ascii value;
		~buffer &&
		((bs = bc % 4 ? bs * 64 + buffer : buffer),
		// and if not first of each 4 characters,
		// convert the first 8 bits to one ascii character
		bc++ % 4)
			? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
			: 0
	) {
		// try to find character in table (0-63, not found => -1)
		buffer = chars.indexOf(buffer);
	}
	return output;
}

function b64DecodeUnicode(str: any) {
	return decodeURIComponent(
		Array.prototype.map
			.call(b64decode(str), (c: any) => {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join(''),
	);
}
