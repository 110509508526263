import saveAs from 'file-saver';
import { License } from '../../entities';
import { env } from '../../enviroment';
import { getHeaders } from '../../utils';

export namespace LicenseService {
	export async function getAll(showDisabled: boolean = false): Promise<License[]> {
		const res = await fetch(`${env.apiUrl}/api/1/License?showDisabled=${showDisabled}`, {
			headers: getHeaders({}),
		});
		if (!res.ok) throw res;
		return await res.json();
	}

	export async function get(licenseNumber: string): Promise<License> {
		const res = await fetch(`${env.apiUrl}/api/1/License?licenseNumber=${licenseNumber}`, {
			headers: getHeaders({}),
		});
		if (!res.ok) throw res;
		const json = await res.json();
		return json[0];
	}

	export async function create(license: Partial<License>): Promise<void> {
		const res = await fetch(`${env.apiUrl}/api/1/License`, {
			method: 'POST',
			body: JSON.stringify({
				...license,
				tier: 'Custom',
			}),
			headers: getHeaders({
				'Content-Type': 'application/json',
				Accept: 'application/octet-stream',
			}),
		});
		if (res.ok) {
			const disposition = res.headers.get('Content-Disposition');
			let filename = 'License.toca';
			if (disposition && disposition.indexOf('attachment') !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches != null && matches[1]) {
					filename = matches[1].replace(/['"]/g, '');
				}
			}
			const blob = await res.blob();

			saveAs(blob, filename);
		} else {
			throw res;
		}
	}

	export async function generate(licenseNumber?: string) {
		if (!licenseNumber) {
			return;
		}
		const res = await fetch(`${env.apiUrl}/api/1/License/Generate?licenseNumber=${licenseNumber}`, {
			headers: getHeaders({
				'Content-Type': 'application/octet-stream',
				Accept: 'application/octet-stream',
			}),
		});
		if (res.ok) {
			const blob = await res.blob();
			saveAs(blob, `License_${licenseNumber}.toca`);
		} else {
			throw res;
		}
	}

	export async function deleteLicense(licenseId: number) {
		const res = await fetch(`${env.apiUrl}/api/1/License/${licenseId}`, {
			method: 'DELETE',
			headers: getHeaders({}),
		});
		if (!res.ok) throw res;
	}
}
