import {
	Alert,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Environment } from '../../entities';
import { DeleteEnvironment } from '../../store/environments';
import { Spacer } from '../Spacer';

export function useDeleteEnvironmentDialog() {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [force, setForce] = useState(false);
	const [environment, setEnvironment] = useState<Environment>();

	const close = () => {
		setOpen(false);
		setEnvironment(undefined);
	};

	const deleteCustomer = useCallback(async () => {
		if (environment?.id) {
			dispatch(new DeleteEnvironment(environment.id, force));
			close();
		}
	}, [environment, force]);

	return {
		open(e: Environment, f: boolean = false) {
			setOpen(true);
			setForce(f);
			setEnvironment(e);
		},
		render() {
			const disableOrDelete = environment?.enabled ? 'Disable' : 'Delete';
			return (
				<Dialog open={open} onClose={() => setOpen(false)}>
					<DialogTitle>
						{disableOrDelete} Environment <code>'{environment?.name}'</code>
					</DialogTitle>
					<DialogContent>
						<Typography>
							You are about to {disableOrDelete.toLowerCase()} <code>'{environment?.name}'</code>.
							This {disableOrDelete.toLowerCase()}s the environment from the platform and they will
							no longer be able to have licenses created for them
						</Typography>
						{force && (
							<>
								<Spacer />
								<Alert severity="error">
									Deleting this environment will delete all licenses associated with this
									environment
								</Alert>
							</>
						)}
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setOpen(false)}>Cancel</Button>
						<Button onClick={deleteCustomer}>{disableOrDelete}</Button>
					</DialogActions>
				</Dialog>
			);
		},
	};
}
