import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	MenuItem,
	TextField,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Organization } from '../../entities';
import { User } from '../../entities/User';
import { CreateUser, UpdateUser } from '../../store/user';
import { validateEmail } from '../../utils';
import { OrganizationService } from '../organization';
import { OutlinedSelect } from '../OutlinedSelect';
import { useRole } from '../RoleProvider';
import { Spacer } from '../Spacer';

export function useCreateUserDialog() {
	const dispatch = useDispatch();
	const { role } = useRole();
	const [open, setOpen] = useState(false);
	const [user, setUser] = useState<User>();
	const [email, setEmail] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [org, setOrg] = useState<number>();
	const [password, setPassword] = useState('');
	const [userType, setUserType] = useState<User['userType']>();
	const [allOrgs, setAllOrgs] = useState<Organization[]>([]);

	const getAllOrgs = useCallback(async () => {
		const orgs = await OrganizationService.getAll();
		setAllOrgs(orgs);
	}, []);

	const close = () => {
		setOpen(false);
		setEmail('');
		setFirstName('');
		setLastName('');
		setOrg(undefined);
		setPassword('');
		setUserType(undefined);
		setUser(undefined);
	};

	useEffect(() => {
		getAllOrgs();
	}, []);

	const emailValid = useMemo(() => !email || validateEmail(email), [email]);

	const createUser = useCallback(async () => {
		if (validateEmail(email)) {
			dispatch(
				new CreateUser({
					username: email,
					firstName,
					lastName,
					emailAddress: email,
					contactNumber: '',
					organizationId: org,
					userType,
					password,
				}),
			);
			close();
		}
	}, [email, firstName, lastName, org, userType, password]);

	const updateUser = useCallback(async () => {
		if (validateEmail(email)) {
			dispatch(
				new UpdateUser({
					id: user?.id,
					username: email,
					firstName,
					lastName,
					emailAddress: email,
					contactNumber: '',
					organizationId: org ?? user?.organizationId,
					userType,
					password,
				}),
			);
			close();
		}
	}, [email, firstName, lastName, org, userType, password]);

	const submit = useCallback(() => {
		if (!!user) {
			updateUser();
		} else {
			createUser();
		}
	}, [email, firstName, lastName, org, userType, password, user]);
	return {
		open(u?: User) {
			setOpen(true);
			if (!!u) {
				setFirstName(u.firstName);
				setLastName(u.lastName);
				setEmail(u.emailAddress);
				const orgId = allOrgs.find(x => x.name === u.organization)?.id;
				if (orgId !== undefined) {
					setOrg(orgId);
				}
				setUserType(u.userType);
				setUser(u);
			}
		},
		render() {
			return (
				<Dialog open={open} onClose={() => setOpen(false)}>
					<DialogTitle>{!!user ? 'Update' : 'Create'} User</DialogTitle>
					<DialogContent>
						<TextField
							label="Email"
							type="email"
							value={email}
							onChange={evt => setEmail(evt.target.value)}
							margin="dense"
							variant="outlined"
							fullWidth
							error={!emailValid}
							helperText={!emailValid ? 'Please enter a vaild email' : ''}
						/>
						<TextField
							label="Password"
							type="password"
							value={password}
							onChange={evt => setPassword(evt.target.value)}
							margin="dense"
							variant="outlined"
							fullWidth
						/>
						<TextField
							label="First Name"
							value={firstName}
							onChange={evt => setFirstName(evt.target.value)}
							margin="dense"
							variant="outlined"
							fullWidth
						/>
						<TextField
							label="Last Name"
							value={lastName}
							onChange={evt => setLastName(evt.target.value)}
							margin="dense"
							variant="outlined"
							fullWidth
						/>
						<Spacer />
						<OutlinedSelect
							label="Orginization"
							value={org}
							onChange={evt => setOrg(evt.target.value as number)}
							fullWidth
						>
							{allOrgs.map(orginzation => (
								<MenuItem key={orginzation.id} value={orginzation.id}>
									{orginzation.name}
								</MenuItem>
							))}
						</OutlinedSelect>
						<Spacer />
						<OutlinedSelect
							fullWidth
							margin="dense"
							label="User Type"
							value={userType}
							onChange={evt => setUserType(evt.target.value as User['userType'])}
						>
							{role === 'Admin' && <MenuItem value="Admin">Admin</MenuItem>}
							{['Admin', 'PartnerAdmin'].includes(role) && (
								<MenuItem value="PartnerAdmin">Partner Admin</MenuItem>
							)}
							<MenuItem value="Partner">Partner</MenuItem>
						</OutlinedSelect>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setOpen(false)}>Cancel</Button>
						<Button disabled={!emailValid} onClick={submit}>
							{!!user ? 'Update' : 'Create'}
						</Button>
					</DialogActions>
				</Dialog>
			);
		},
	};
}
