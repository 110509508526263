import { createEntityAdapter, EntityState } from 'redux-ngrx-entity';
import { Organization } from '../../entities';
import { OrganizationActions, OrganizationActionTypes } from './organization.actions';

export const ORGANIZATION_FEAUTURE_KEY = 'organization';

export interface OrganizationState extends EntityState<Organization> {
	loaded: boolean;
}

export const organizationAdapter = createEntityAdapter<Organization>({
	selectId: entity => entity.id ?? 0,
});

const initialState: OrganizationState = organizationAdapter.getInitialState({
	loaded: false,
});

export function reducer(
	state: OrganizationState = initialState,
	action: OrganizationActions,
): OrganizationState {
	switch (action.type) {
		case OrganizationActionTypes.LoadOrganizationsSuccess: {
			return organizationAdapter.upsertMany(action.organizations, { ...state, loaded: true });
		}
		case OrganizationActionTypes.LoadOrganizationsFail: {
			return { ...state, loaded: true };
		}
		case OrganizationActionTypes.CreateOrganizationSuccess: {
			return organizationAdapter.addOne(action.organization, state);
		}
		case OrganizationActionTypes.UpdateOrganizationSuccess: {
			return organizationAdapter.updateOne(
				{ id: action.organization.id, changes: { ...action.organization } },
				state,
			);
		}
		case OrganizationActionTypes.DeleteOrganizationSuccess: {
			return organizationAdapter.removeOne(action.orgId, state);
		}
		case '@toca/clear': {
			return organizationAdapter.removeAll({ ...state, loaded: false });
		}
	}

	return state;
}
