import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import StorageIcon from '@mui/icons-material/Storage';
import {
	Avatar,
	Button,
	Card,
	IconButton,
	LinearProgress,
	Paper,
	Tooltip,
	Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Environment } from '../../entities';
import { getCustomerById, getCustomersLoaded, LoadCustomers } from '../../store/customer';
import {
	getEnvironmentsForCustomer,
	getEnvironmentsLoadedPerCustomer,
	LoadEnvironments,
} from '../../store/environments';
import { useCreateEnvironment, useDeleteEnvironmentDialog } from '../environment';
import { useRole } from '../RoleProvider';
import { Spacer } from '../Spacer';
import { EnvironmentUsage } from '../usage/EnvironmentUsage';

const useStyles = makeStyles()(theme => ({
	container: {
		margin: theme.spacing(1),
		padding: theme.spacing(2),
		[theme.breakpoints.only('xs')]: {
			margin: 0,
			padding: theme.spacing(1),
		},
		display: 'flex',
		flexDirection: 'column',
		minHeight: 500,
	},
	noEnvs: {
		margin: 'auto',
		textAlign: 'center',
	},
	title: {
		display: 'flex',
		flexWrap: 'wrap',
		margin: theme.spacing(1),
	},
	controls: {
		display: 'flex',
		flexWrap: 'wrap',
		margin: theme.spacing(1),
		[theme.breakpoints.only('xs')]: {
			flexDirection: 'column-reverse',
		},
	},
	envs: {
		display: 'grid',
		gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
		[theme.breakpoints.only('xs')]: {
			gridTemplateColumns: 'repeat(auto-fill, minmax(calc(100% - 16px), 0.4fr))',
		},
		gridGap: theme.spacing(1),
	},
}));

export default function Customer() {
	const dispatch = useDispatch();
	const { isAdmin, isPartnerAdmin } = useRole();
	const { id } = useParams<{ id: string }>();
	const nav = useNavigate();
	const { classes } = useStyles();
	const customer = useSelector(getCustomerById(parseInt(id ?? '', 10)));
	const customersLoaded = useSelector(getCustomersLoaded);
	const envs = useSelector(getEnvironmentsForCustomer(customer?.id));
	const environmentsLoaded = useSelector(getEnvironmentsLoadedPerCustomer(customer?.id));
	const enviromentDialog = useCreateEnvironment(id ?? '');
	const deleteEnvironmentDialog = useDeleteEnvironmentDialog();

	useEffect(() => {
		if (!customersLoaded) {
			dispatch(new LoadCustomers(id));
		}
	}, [customersLoaded]);

	useEffect(() => {
		if (!environmentsLoaded && customer) {
			dispatch(new LoadEnvironments(customer?.id));
		}
	}, [environmentsLoaded, customer]);

	const deleteEnv = useCallback(
		async (env: Environment) => {
			deleteEnvironmentDialog.open(env, !env.enabled && (isAdmin || isPartnerAdmin));
		},
		[id],
	);

	const editEnv = useCallback(env => {
		enviromentDialog.open(env);
	}, []);

	return (
		<div className={classes.container}>
			<Paper className={classes.container}>
				<div>
					<IconButton onClick={() => nav(-1)}>
						<ArrowBackIcon />
					</IconButton>
					<Spacer />
					<div>
						<Typography variant="h4">
							<b>{customer?.name}</b>
						</Typography>
						{!(customer?.enabled ?? true) && (
							<Typography variant="caption">(Cutomer Disabled)</Typography>
						)}
					</div>
				</div>

				<Spacer />
				<div className={classes.title}>
					<Typography variant="h5" sx={{ flex: 1 }}>
						Environments
					</Typography>

					<Button
						disabled={!customer?.enabled}
						variant="contained"
						onClick={() => enviromentDialog.open()}
					>
						<AddIcon /> Add Environment
					</Button>
				</div>
				<Spacer />
				{!environmentsLoaded && <LinearProgress />}
				{environmentsLoaded && envs.length === 0 && (
					<div className={classes.noEnvs}>
						<Typography>No Environments</Typography>
						<Typography variant="caption">Please create an environment</Typography>
					</div>
				)}
				<div className={classes.envs}>
					{envs.map(env => (
						<EnvironmentCard key={env.id} onDelete={deleteEnv} onEdit={editEnv} environment={env} />
					))}
				</div>
				<Spacer />
				{!!id && <EnvironmentUsage usageQuery={{ customerId: parseInt(id, 10) }} />}
			</Paper>
			{enviromentDialog.render()}
			{deleteEnvironmentDialog.render()}
		</div>
	);
}

const useEnvStyles = makeStyles()(theme => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		margin: theme.spacing(1),
		padding: theme.spacing(2),
		width: 400,
		[theme.breakpoints.only('xs')]: {
			width: '100%',
		},
	},
	text: {
		overflowX: 'hidden',
		textOverflow: 'ellipsis',
	},
}));

interface EnviromentProps {
	environment: Environment;
	onDelete(env: Environment): void;
	onEdit(env: Environment): void;
}

function EnvironmentCard({ environment, onDelete, onEdit }: EnviromentProps) {
	const { name, enabled } = environment;
	const { classes } = useEnvStyles();
	return (
		<Card elevation={5} className={classes.container}>
			<Avatar sx={{ bgcolor: enabled ? 'primary.main' : '' }}>
				<StorageIcon />
			</Avatar>
			<Spacer />
			<Typography
				variant="h5"
				className={classes.text}
				sx={{ flex: 1, color: !enabled ? grey[500] : '' }}
			>
				{name}
			</Typography>
			<Spacer />
			<Tooltip title="Edit">
				<IconButton onClick={() => onEdit(environment)}>
					<Edit />
				</IconButton>
			</Tooltip>
			<Tooltip title={enabled ? 'Disable' : 'Delete'}>
				<IconButton onClick={() => onDelete(environment)}>
					<DeleteIcon />
				</IconButton>
			</Tooltip>
		</Card>
	);
}
