import { User } from '../../entities/User';
import { env } from '../../enviroment';
import { getHeaders } from '../../utils';

export namespace UserService {
	export async function getAll(): Promise<User[]> {
		const res = await fetch(`${env.apiUrl}/api/1/User`, {
			headers: getHeaders({ 'Content-Type': 'application/json' }),
		});
		if (!res.ok) throw res;
		return await res.json();
	}
	export async function create(user: Partial<User>) {
		const res = await fetch(`${env.apiUrl}/api/1/User`, {
			method: 'POST',
			headers: getHeaders({ 'Content-Type': 'application/json' }),
			body: JSON.stringify(user),
		});
		if (!res.ok) throw res;
		return await res.json();
	}
	export async function update(user: Partial<User>) {
		const res = await fetch(`${env.apiUrl}/api/1/User`, {
			method: 'PATCH',
			headers: getHeaders({ 'Content-Type': 'application/json' }),
			body: JSON.stringify(user),
		});
		if (!res.ok) throw res;
		return await res.json();
	}
	export async function deleteUser(userId: number) {
		const res = await fetch(`${env.apiUrl}/api/1/User/${userId}`, {
			method: 'DELETE',
			headers: getHeaders({ 'Content-Type': 'application/json' }),
		});
		if (!res.ok) throw res;
	}
}
