import { makeStyles } from 'tss-react/mui';

export const useHomeStyles = makeStyles()(theme => ({
	container: {
		margin: theme.spacing(1),
		[theme.breakpoints.only('xs')]: {
			margin: 0,
		},
		padding: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		minHeight: 500,
	},
	title: {
		display: 'flex',
		flexWrap: 'wrap',
		[theme.breakpoints.only('xs')]: {
			alignItems: 'flex-end',
			'& > button': {
				marginTop: theme.spacing(1),
			},
		},
	},
	icon: {
		margin: 'auto',
	},
	pointer: {
		cursor: 'pointer',
	},
	disabled: {
		opacity: 0.5,
	},
}));
