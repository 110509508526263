import { createSelector } from 'reselect';
import { createFeatureSelector } from '../../utils/createFeatureSelctor';
import {
	ENVIRONMENT_FEAUTURE_KEY,
	environmentAdapter,
	EnvironmentState,
} from './environments.reducer';

const getEnvironmentFeatureState =
	createFeatureSelector<EnvironmentState>(ENVIRONMENT_FEAUTURE_KEY);

const { selectAll, selectEntities } = environmentAdapter.getSelectors();

export const getAllEnvironments = createSelector(getEnvironmentFeatureState, selectAll);
export const getEnvironmentEntities = createSelector(getEnvironmentFeatureState, selectEntities);

export const getEnvironmentsLoaded = createSelector(
	getEnvironmentFeatureState,
	state => state.loaded,
);
export const getEnvironmentsLoadedPerCustomer = (id: number | undefined) =>
	createSelector(
		getEnvironmentFeatureState,
		state =>
			!!id && id.toString() in state.loadedPerCustomer && state.loadedPerCustomer[id?.toString()],
	);
export const getEnvironmentsForCustomer = (id: number | undefined) =>
	createSelector(getAllEnvironments, entities => entities.filter(x => x.customerId === id));
