import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ spacing: number }>()((theme, props) => ({
	spacer: {
		margin: theme.spacing(props.spacing),
	},
}));

export function Spacer({ spacing = 1 }: { spacing?: number }) {
	const { classes } = useStyles({ spacing });
	return <div className={classes.spacer} />;
}
