import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { User } from '../../entities/User';
import { DeleteUser } from '../../store/user';

export function useDeleteUserDialog() {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [user, setUser] = useState<User>();

	const close = () => {
		setOpen(false);
		setUser(undefined);
	};

	const deleteUser = useCallback(async () => {
		if (user?.id) {
			dispatch(new DeleteUser(user.id));
			close();
		}
	}, [user]);

	return {
		open(u: User) {
			setOpen(true);
			setUser(u);
		},
		render() {
			return (
				<Dialog open={open} onClose={() => setOpen(false)}>
					<DialogTitle>
						Delete User '{user?.firstName} {user?.lastName}'
					</DialogTitle>
					<DialogContent>
						<Typography>
							You are about to delete '{user?.firstName} {user?.lastName}'. This remove the user
							from the platform and they will no longer be able to login
						</Typography>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setOpen(false)}>Cancel</Button>
						<Button onClick={deleteUser}>Confirm</Button>
					</DialogActions>
				</Dialog>
			);
		},
	};
}
