import { Checkbox, FormControlLabel, Grid, Skeleton, Tab, Tabs } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Usage } from '../../entities';
import { transformUsage } from '../../utils/transformUsage';
import { Spacer } from '../Spacer';
import { ChartUsage, LineGraph } from './LineGraph';
import { UsageQuery, UsageService } from './service';

const useStyles = makeStyles()(theme => ({
	controls: {
		display: 'flex',
		flexWrap: 'wrap',
		margin: theme.spacing(1),
		[theme.breakpoints.only('xs')]: {
			flexDirection: 'column-reverse',
		},
	},
	envs: {
		display: 'grid',
		gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
		[theme.breakpoints.only('xs')]: {
			gridTemplateColumns: 'repeat(auto-fill, minmax(calc(100% - 16px), 0.4fr))',
		},
		gridGap: theme.spacing(1),
	},
}));

export interface EnviromentUsageProps {
	usageQuery: UsageQuery;
}

export function EnvironmentUsage({ usageQuery }: EnviromentUsageProps) {
	const { classes } = useStyles();
	const [usage, setUsage] = useState<Usage[]>();
	const [period, setPeriod] = useState<'week' | 'month' | '6M' | 'YTD' | 'max'>('week');
	const [stacked, setStacked] = useState(false);

	const getUsage = useCallback(async () => {
		let options: UsageQuery = {};
		let today = new Date();
		switch (period) {
			case 'week':
				today.setDate(today.getDate() - 7);
				options = {
					fromDateTime: today.toISOString(),
					toDateTime: new Date().toISOString(),
					usageGrouping: 'Day',
				};
				break;
			case 'month':
				today.setMonth(today.getMonth() - 1);
				options = {
					fromDateTime: today.toISOString(),
					toDateTime: new Date().toISOString(),
					usageGrouping: 'Week',
				};
				break;
			case '6M':
				today.setMonth(today.getMonth() - 6);
				options = {
					fromDateTime: today.toISOString(),
					toDateTime: new Date().toISOString(),
					usageGrouping: 'Week',
				};
				break;
			case 'YTD':
				today.setDate(1);
				today.setMonth(0);
				options = {
					fromDateTime: today.toISOString(),
					toDateTime: new Date().toISOString(),
					usageGrouping: 'Month',
				};
				break;
			case 'max':
				options = {
					usageGrouping: 'Month',
				};
				break;
		}
		const usage = await UsageService.getUsage({ ...usageQuery, ...options });
		const transformed = transformUsage(usage);
		setUsage(transformed);
	}, [usageQuery, period]);

	useEffect(() => {
		getUsage();
	}, [period]);

	const getChartData: (property: string) => ChartUsage | undefined = useCallback(
		(property: string) => {
			if (usage) {
				const series = usage.map(x => ({
					name: x.environmentName,
					data: x.usages.map(u => [(u.dateTime as Date).getTime(), u[property]]),
				}));
				const min = Math.min(
					...series.map(x => Math.min(...x.data.map(d => d[0]).filter(d => !isNaN(d)))),
				);
				return {
					series,
					tooltip: {
						x: {
							format: 'dd/MM/yyyy',
						},
					},
					xAxis: {
						min,
						labels: {
							format: 'dd/MM/yyyy',
						},
						type: 'datetime',
					},
				};
			}
			return;
		},
		[usage],
	);

	const appUserSeries = useMemo<ChartUsage | undefined>(() => getChartData('appUsers'), [usage]);

	const executionSeries = useMemo<ChartUsage | undefined>(
		() => getChartData('executions'),
		[usage],
	);

	const executionSecondsSeries = useMemo<ChartUsage | undefined>(
		() => getChartData('executionSeconds'),
		[usage],
	);

	return (
		<>
			<div className={classes.controls}>
				<Tabs
					value={period}
					variant="scrollable"
					onChange={(_, val) => setPeriod(val)}
					sx={{ flex: 1, width: '100%' }}
				>
					<Tab label="7D" value="week" />
					<Tab label="1M" value="month" />
					<Tab label="6M" value="6M" />
					<Tab label="YTD" value="YTD" />
					<Tab label="Max" value="max" />
				</Tabs>
				<FormControlLabel
					control={<Checkbox checked={stacked} onChange={evt => setStacked(evt.target.checked)} />}
					label="Stacked"
				/>
			</div>

			<Spacer />
			<Grid container spacing={2}>
				<Grid item xs={12} md={4}>
					<div>
						{appUserSeries ? (
							<LineGraph
								series={appUserSeries.series}
								xAxis={appUserSeries.xAxis}
								tooltip={appUserSeries.tooltip}
								height={400}
								stacked={stacked}
								title="App Users"
							/>
						) : (
							<Skeleton variant="rectangular" height={400} />
						)}
					</div>
				</Grid>
				<Grid item xs={12} md={4}>
					<div>
						{executionSeries ? (
							<LineGraph
								series={executionSeries.series}
								xAxis={executionSeries.xAxis}
								tooltip={executionSeries.tooltip}
								height={400}
								stacked={stacked}
								title="Executions"
							/>
						) : (
							<Skeleton variant="rectangular" height={400} />
						)}
					</div>
				</Grid>
				<Grid item xs={12} md={4}>
					<div>
						{executionSecondsSeries ? (
							<LineGraph
								series={executionSecondsSeries.series}
								xAxis={executionSecondsSeries.xAxis}
								tooltip={executionSecondsSeries.tooltip}
								height={400}
								stacked={stacked}
								title="Execution Seconds"
							/>
						) : (
							<Skeleton variant="rectangular" height={400} />
						)}
					</div>
				</Grid>
			</Grid>
		</>
	);
}
