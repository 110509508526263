import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import GroupIcon from '@mui/icons-material/Group';
import MenuIcon from '@mui/icons-material/Menu';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import {
	AppBarProps,
	Avatar,
	Button,
	Chip,
	Divider,
	Drawer,
	Icon,
	IconButton,
	LinearProgress,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	AppBar as MuiAppBar,
	styled,
	Toolbar,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import { Suspense, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { getTokenName, useAppTheme } from '../utils';
import { AuthCheck, nonAuthedPages } from './AuthCheck';
import { useRole } from './RoleProvider';
import { Spacer } from './Spacer';

const drawerWidth = 240;

const useStyles = makeStyles()(theme => ({
	appBar: {
		display: 'flex',
		marginLeft: theme.spacing(2),
	},
	pages: {
		justifyContent: 'end',
	},
	outlet: {
		marginTop: 60,
		zIndex: 10000,
		height: 'calc(100vh - 60px)',
		padding: theme.spacing(1),
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	outletOpen: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	video: {
		position: 'fixed',
		right: 0,
		bottom: 0,
		minWidth: '100%',
		minHeight: '100%',
	},
	buttons: {
		color: 'black',
		borderColor: 'black',
		'&:': {
			borderColor: 'black',
		},
	},
	pointer: {
		cursor: 'pointer',
	},
	flex: {
		flex: 1,
	},
}));

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: prop => prop !== 'open',
})<AppBarProps & { open: boolean }>(({ theme, open }) => ({
	transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

export function Nav() {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState<Element>();
	const theme = useTheme();
	const { toggleMode } = useAppTheme();
	const { isAdmin, isPartnerAdmin, role, setRole } = useRole();
	const location = useLocation();
	const [open, setOpen] = useState(false);
	const { classes, cx } = useStyles();
	const name = useMemo(() => getTokenName(), [role]);
	const toggle = () => setOpen(o => !o);

	const handleClose = () => setAnchorEl(undefined);

	const logout = () => {
		handleClose();
		localStorage.removeItem('access_token');
		setOpen(false);
		setRole('');
		navigate('/login');
	};

	const nonAuthedPage = useMemo(
		() => nonAuthedPages.some(x => location.pathname.includes(x)),
		[location],
	);
	return (
		<>
			<AuthCheck />
			<AppBar position="fixed" color="primary" open={open}>
				<Toolbar disableGutters className={classes.appBar}>
					{!nonAuthedPage && (
						<IconButton onClick={toggle}>
							<MenuIcon />
						</IconButton>
					)}
					<Spacer />
					<img
						src="./tl_ammonite_white.svg"
						width={40}
						height={40}
						onClick={() => navigate('/')}
						className={classes.pointer}
					/>
					&nbsp;
					<Typography
						variant="h6"
						onClick={() => navigate('/')}
						className={cx(classes.pointer, classes.flex)}
					>
						Toca Licensing
					</Typography>
					<Tooltip title={theme.palette.mode === 'dark' ? 'Light Mode' : 'Dark Mode'}>
						<IconButton onClick={toggleMode}>
							<Icon>{theme.palette.mode === 'dark' ? 'light_mode' : 'dark_mode'}</Icon>
						</IconButton>
					</Tooltip>
					{!nonAuthedPage && (
						<>
							<Button
								sx={{ textTransform: 'none', color: 'primary.contrastText' }}
								onClick={evt => setAnchorEl(evt.currentTarget)}
							>
								<Avatar sx={{ bgcolor: `primary.${theme.palette.mode}` }}>
									{name ? name[0].toUpperCase() : ''}
								</Avatar>
								<Typography variant="caption" sx={{ marginLeft: 1 }}>
									{name}
								</Typography>
								<Icon>arrow_drop_down</Icon>
							</Button>
							<Menu
								anchorEl={anchorEl}
								open={Boolean(anchorEl)}
								onClose={handleClose}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
							>
								<MenuItem onClick={logout}>
									<Icon>logout</Icon>&nbsp; Log out
								</MenuItem>
							</Menu>
						</>
					)}
				</Toolbar>
			</AppBar>
			<Drawer
				variant="persistent"
				anchor="left"
				open={open}
				onClose={() => setOpen(false)}
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						boxSizing: 'border-box',
					},
				}}
			>
				<List>
					<ListItem disablePadding sx={{ height: 56 }}>
						<Chip label={role} sx={{ flex: 1, marginLeft: 1 }} />
						<IconButton onClick={() => setOpen(false)} sx={{ marginRight: 1 }}>
							<ChevronLeftIcon />
						</IconButton>
					</ListItem>
					<Divider />
					<ListItem disablePadding>
						<ListItemButton onClick={() => navigate('/')}>
							<ListItemIcon>
								<Icon>home</Icon>
							</ListItemIcon>
							<ListItemText primary="Home" />
						</ListItemButton>
					</ListItem>
					{isAdmin && (
						<ListItem disablePadding>
							<ListItemButton onClick={() => navigate('/organization')}>
								<ListItemIcon>
									<Icon>corporate_fare</Icon>
								</ListItemIcon>
								<ListItemText primary="Organizations" />
							</ListItemButton>
						</ListItem>
					)}
					<ListItem disablePadding>
						<ListItemButton onClick={() => navigate('/license')}>
							<ListItemIcon>
								<ReceiptLongIcon />
							</ListItemIcon>
							<ListItemText primary="Licenses" />
						</ListItemButton>
					</ListItem>
					{(isAdmin || isPartnerAdmin) && (
						<ListItem disablePadding>
							<ListItemButton onClick={() => navigate('/users')}>
								<ListItemIcon>
									<GroupIcon />
								</ListItemIcon>
								<ListItemText primary="Users" />
							</ListItemButton>
						</ListItem>
					)}
				</List>
			</Drawer>
			<Suspense fallback={<LinearProgress />}>
				<div className={cx(classes.outlet, open ? classes.outletOpen : '')}>
					<Outlet />
				</div>
			</Suspense>
		</>
	);
}
