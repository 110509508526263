import { createSelector } from 'reselect';
import { createFeatureSelector } from '../../utils/createFeatureSelctor';
import { LICENSE_FEAUTURE_KEY, licenseAdapter, LicenseState } from './licences.reducer';

const getLicenseFeatureState = createFeatureSelector<LicenseState>(LICENSE_FEAUTURE_KEY);

const { selectAll, selectEntities } = licenseAdapter.getSelectors();

export const getAllLicenses = createSelector(getLicenseFeatureState, selectAll);
export const getLicenseEntities = createSelector(getLicenseFeatureState, selectEntities);

export const getLicensesLoaded = createSelector(getLicenseFeatureState, state => state.loaded);
export const getLicense = (licenseNumber: string) =>
	createSelector(getAllLicenses, state => state.find(x => x.licenseNumber === licenseNumber));
