import { createEntityAdapter, EntityState } from 'redux-ngrx-entity';
import { User } from '../../entities';
import { UserActions, UserActionTypes } from './user.actions';

export const USER_FEAUTURE_KEY = 'user';

export interface UserState extends EntityState<User> {
	loaded: boolean;
}

export const userAdapter = createEntityAdapter<User>({
	selectId: entity => entity.id ?? 0,
});

const initialState: UserState = userAdapter.getInitialState({
	loaded: false,
});

export function reducer(state: UserState = initialState, action: UserActions): UserState {
	switch (action.type) {
		case UserActionTypes.LoadUsersSuccess: {
			return userAdapter.addMany(action.users, { ...state, loaded: true });
		}
		case UserActionTypes.LoadUsersFail: {
			return { ...state, loaded: true };
		}
		case UserActionTypes.CreateUserSuccess: {
			return userAdapter.addOne(action.user, state);
		}
		case UserActionTypes.DeleteUserSuccess: {
			return userAdapter.removeOne(action.userId, state);
		}
		case UserActionTypes.UpdateUserSuccess: {
			return userAdapter.upsertOne(action.user, state);
		}
		case '@toca/clear': {
			return userAdapter.removeAll({ ...state, loaded: false });
		}
	}

	return state;
}
