import { Action } from 'redux';
import { Environment } from '../../entities';
import { Clear } from '../clear';

export enum EnvironmentActionTypes {
	LoadEnvironments = '[Environment] Load Environments',
	LoadEnvironmentsSuccess = '[Environment] Load Environments Success',
	LoadEnvironmentsFail = '[Environment] Load Environments Fail',

	LoadSingleEnvironment = '[Environment] Load single Environment',
	LoadSingleEnvironmentSuccess = '[Environment] Load single Environment Success',

	CreateEnvironment = '[Environment] Create Environment',
	CreateEnvironmentSuccess = '[Environment] Create Environment Success',
	CreateEnvironmentFail = '[Environment] Create Environment Fail',

	UpdateEnvironment = '[Environment] Update Environment',
	UpdateEnvironmentSuccess = '[Environment] Update Environment Success',
	UpdateEnvironmentFail = '[Environment] Update Environment Fail',

	DeleteEnvironment = '[Environment] Delete Environment',
	DeleteEnvironmentSuccess = '[Environment] Delete Environment Success',
	DeleteEnvironmentFail = '[Environment] Delete Environment Fail',

	ClearLoadedEnvironments = '[Environment] Clear Environments',
}

export class LoadEnvironments implements Action {
	public readonly type = EnvironmentActionTypes.LoadEnvironments;
	constructor(public customerId?: number) {}
}

export class LoadEnvironmentsSuccess implements Action {
	public readonly type = EnvironmentActionTypes.LoadEnvironmentsSuccess;
	constructor(public environments: Environment[], public customerId: number) {}
}

export class LoadEnvironmentsFail implements Action {
	public readonly type = EnvironmentActionTypes.LoadEnvironmentsFail;
	constructor(public error: any) {}
}

export class LoadSingleEnvironment implements Action {
	public readonly type = EnvironmentActionTypes.LoadSingleEnvironment;
	constructor(public environmentId: number) {}
}

export class LoadSingleEnvironmentSuccess implements Action {
	public readonly type = EnvironmentActionTypes.LoadSingleEnvironmentSuccess;
	constructor(public environment: Environment) {}
}

export class CreateEnvironment implements Action {
	public readonly type = EnvironmentActionTypes.CreateEnvironment;
	constructor(public customerId: number, public name: string) {}
}

export class CreateEnvironmentSuccess implements Action {
	public readonly type = EnvironmentActionTypes.CreateEnvironmentSuccess;
	constructor(public environment: Environment) {}
}

export class CreateEnvironmentFail implements Action {
	public readonly type = EnvironmentActionTypes.CreateEnvironmentFail;
	constructor(public error: any) {}
}

export class DeleteEnvironment implements Action {
	public readonly type = EnvironmentActionTypes.DeleteEnvironment;
	constructor(public environmentId: number, public force: boolean = false) {}
}

export class UpdateEnvironment implements Action {
	public readonly type = EnvironmentActionTypes.UpdateEnvironment;
	constructor(public environment: Partial<Environment>) {}
}

export class UpdateEnvironmentSuccess implements Action {
	public readonly type = EnvironmentActionTypes.UpdateEnvironmentSuccess;
	constructor(public environment: Environment) {}
}

export class UpdateEnvironmentFail implements Action {
	public readonly type = EnvironmentActionTypes.UpdateEnvironmentFail;
	constructor(public error: any) {}
}

export class DeleteEnvironmentSuccess implements Action {
	public readonly type = EnvironmentActionTypes.DeleteEnvironmentSuccess;
	constructor(public environmentId: number) {}
}

export class DeleteEnvironmentFail implements Action {
	public readonly type = EnvironmentActionTypes.DeleteEnvironmentFail;
	constructor(public error: any) {}
}

export class ClearLoadedEnvironments implements Action {
	public readonly type = EnvironmentActionTypes.ClearLoadedEnvironments;
}

export type EnvironmentActions =
	| LoadEnvironments
	| LoadEnvironmentsSuccess
	| LoadEnvironmentsFail
	| LoadSingleEnvironment
	| LoadSingleEnvironmentSuccess
	| CreateEnvironment
	| CreateEnvironmentSuccess
	| CreateEnvironmentSuccess
	| UpdateEnvironment
	| UpdateEnvironmentSuccess
	| UpdateEnvironmentFail
	| DeleteEnvironment
	| DeleteEnvironmentSuccess
	| DeleteEnvironmentFail
	| ClearLoadedEnvironments
	| Clear;
