import {
	Alert,
	alpha,
	Box,
	Button,
	Checkbox,
	Collapse,
	FormControlLabel,
	MenuItem,
	Step,
	StepLabel,
	Stepper,
	TextField,
	Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { Environment, License } from '../../entities';
import { getAllCustomers, LoadCustomers } from '../../store/customer';
import { CreateLicense } from '../../store/license';
import { getAllOrgs, getOrganizationLoaded, LoadOrganizations } from '../../store/organization';
import { getAccessToken, getClaims } from '../../utils';
import { EnvironmentService } from '../environment';
import { OutlinedSelect } from '../OutlinedSelect';
import { useRole } from '../RoleProvider';
import { Spacer } from '../Spacer';

const useStyles = makeStyles()(theme => ({
	container: {
		margin: theme.spacing(1),
		minHeight: 600,
		position: 'relative',
	},
	sliders: {
		display: 'grid',
		gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
		justifyContent: 'center',
		margin: theme.spacing(1),
	},
	title: {
		display: 'flex',
		flexDirection: 'column',
	},
	other: {
		backgroundColor: alpha(theme.palette.primary.main, 0.2),
	},
}));

export function endDate() {
	const currentDate = getCurrentDate();
	currentDate.setFullYear(currentDate.getFullYear() + 1);
	return currentDate;
}

export function download(content, fileName, contentType) {
	var a = document.createElement('a');
	var file = new Blob([content], { type: contentType });
	a.href = URL.createObjectURL(file);
	a.download = fileName;
	a.click();
}

const guiBotSteps = [
	{ value: 1 },
	{ value: 5 },
	{ value: 10 },
	{ value: 15 },
	{ value: 25 },
	{ value: 50 },
	{ value: 100 },
];
const executionSteps = [
	{ value: 100 },
	{ value: 250 },
	{ value: 500 },
	{ value: 1000 },
	{ value: 5000 },
	{ value: 10000 },
];
const executionSecondsSteps = [
	{ value: 3600, label: '1 hr' },
	{ value: 18000, label: '5 hrs' },
	{ value: 36000, label: '10 hrs' },
	{ value: 86400, label: '24 hrs' },
	{ value: 172800, label: '48 hrs' },
];
const appUserSteps = [
	{ value: 10 },
	{ value: 25 },
	{ value: 50 },
	{ value: 100 },
	{ value: 250 },
	{ value: 500 },
	{ value: 1000 },
];
const toleranceSteps = [
	{ value: 5, label: '5%' },
	{ value: 15, label: '15%' },
	{ value: 20, label: '20%' },
	{ value: 25, label: '25%' },
	{ value: 30, label: '30%' },
	{ value: 35, label: '35%' },
	{ value: 40, label: '40%' },
];

export interface LicenseFormProps {
	onClose(): void;
	existingLicense?: License;
}

function getCurrentDate() {
	const today = new Date();
	today.setUTCHours(0);
	today.setUTCMinutes(0);
	today.setUTCSeconds(0);
	today.setUTCMilliseconds(0);
	return today;
}

export default function LicenseForm({ onClose, existingLicense }: LicenseFormProps) {
	const { classes } = useStyles();
	const { isAdmin } = useRole();
	const ref = useRef<HTMLDivElement | null>(null);
	const dispatch = useDispatch();
	const [org, setOrg] = useState<number>();
	const [customer, setCustomer] = useState<number>();
	const allOrgs = useSelector(getAllOrgs);
	const orgsLoaded = useSelector(getOrganizationLoaded);
	const customers = useSelector(getAllCustomers);
	const [activeStep, setActiveStep] = useState(0);
	const [allEnvs, setAllEnvs] = useState<Environment[]>([]);
	const [license, setLicense] = useState<License>({
		invoiceNumber: '',
		environmentName: '',
		environmentId: null,
		startDate: getCurrentDate(),
		endDate: endDate(),
		tier: 'custom',
		limits: {
			appUsers: -1,
			executions: -1,
			executionSeconds: -1,
			guiBots: -1,
			tolerance: -1,
			gracePeriod: -1,
		},
		offline: false,
		enabled: true,
	});

	useEffect(() => {
		if (existingLicense) {
			setLicense({
				...existingLicense,
				startDate: getCurrentDate(),
				endDate: endDate(),
			});
			setOrg(existingLicense.organizationId);
			setCustomer(existingLicense.customerId);
		}
	}, [existingLicense]);

	useEffect(() => {
		if (!orgsLoaded && isAdmin) {
			dispatch(new LoadOrganizations());
			dispatch(new LoadCustomers());
		}
	}, [orgsLoaded, isAdmin]);

	const setLicenseValue = (property: string, val: any) =>
		setLicense(x => ({ ...x, [property]: val }));
	const setLimitValues = (property: string, val: any) =>
		setLicense(x => ({ ...x, limits: { ...x.limits, [property]: val } }));

	const submit = useCallback(async () => {
		dispatch(new CreateLicense(license));
		onClose();
	}, [license]);

	const orgName = useMemo(() => allOrgs.find(x => x.id === org)?.name, [org, allOrgs]);

	useEffect(() => {
		const token = getAccessToken();
		if (token && !existingLicense) {
			const claims = getClaims(token);
			const org = allOrgs.find(x => x.name === claims.Organization);
			if (org) {
				setOrg(org.id);
			}
		}
	}, [allOrgs]);

	const getAllEnvs = useCallback(async () => {
		const res = await EnvironmentService.getAll(customer);
		setAllEnvs(res.filter(x => x.enabled));
	}, [customer]);

	useEffect(() => {
		if (customer) {
			getAllEnvs();
		}
	}, [customer]);

	const nextDisabled = useMemo(() => {
		if (activeStep === 0) {
			return !(customer && org && license.environmentId);
		}
		return false;
	}, [customer, org, license]);

	const filteredCustomers = useMemo(
		() => customers.filter(x => x.organizationId === org),
		[customers, org],
	);

	const filteredEnvironments = useMemo(
		() => allEnvs.filter(x => x.customerId === customer),
		[customer, allEnvs],
	);

	return (
		<div className={classes.container} ref={ref}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<Stepper activeStep={activeStep} alternativeLabel>
					<Step>
						<StepLabel>Environment</StepLabel>
					</Step>
					<Step>
						<StepLabel>Details</StepLabel>
					</Step>
					<Step>
						<StepLabel>Limits</StepLabel>
					</Step>
				</Stepper>
				<StepContent step={0} activeStep={activeStep}>
					{isAdmin ? (
						<OutlinedSelect
							label="Organisation"
							margin="dense"
							required
							size="small"
							fullWidth
							value={org ?? -1}
							onChange={evt => setOrg(evt.target.value as number)}
						>
							{allOrgs.map(org => (
								<MenuItem key={org.id} value={org.id}>
									{org.name}
								</MenuItem>
							))}
						</OutlinedSelect>
					) : (
						<TextField
							margin="dense"
							size="small"
							required
							value={orgName ?? org}
							variant="outlined"
							fullWidth
							disabled
							label="Organisation"
						/>
					)}
					<OutlinedSelect
						label="Customer"
						size="small"
						fullWidth
						required
						key={customer}
						value={customer}
						onChange={evt => setCustomer(evt.target.value as number)}
						margin="dense"
						notched={!!customer}
						labelProps={{ shrink: !!customer }}
					>
						{filteredCustomers.map(cus => (
							<MenuItem key={cus.id} value={cus.id}>
								{cus.name}
							</MenuItem>
						))}
					</OutlinedSelect>
					<OutlinedSelect
						label="Environment"
						size="small"
						required
						fullWidth
						value={license.environmentId}
						onChange={evt => setLicenseValue('environmentId', evt.target.value as number)}
						margin="dense"
						notched={!!license?.environmentId}
						labelProps={{ shrink: !!license.environmentId }}
					>
						{filteredEnvironments.map(env => (
							<MenuItem key={env.id} value={env.id}>
								{env.name}
							</MenuItem>
						))}
					</OutlinedSelect>
				</StepContent>
				<StepContent step={1} activeStep={activeStep}>
					<TextField
						margin="dense"
						size="small"
						value={license.invoiceNumber}
						onChange={evt => setLicenseValue('invoiceNumber', evt.target.value)}
						variant="outlined"
						fullWidth
						label="Invoice"
						helperText="This should match to the Xero invoice number"
					/>
					<Spacer />
					<DatePicker
						label="Start Time"
						slotProps={{
							textField: {
								margin: 'dense',
								size: 'small',
							},
						}}
						formatDensity="dense"
						sx={{ width: '100%' }}
						disablePast
						format="DD/MM/YYYY"
						value={dayjs(license.startDate)}
						onChange={val => setLicenseValue('startDate', val)}
					/>
					<DatePicker
						label="End Time"
						formatDensity="dense"
						sx={{ width: '100%' }}
						slotProps={{
							textField: {
								margin: 'dense',
								size: 'small',
							},
						}}
						disablePast
						minDate={dayjs(license.startDate)}
						format="DD/MM/YYYY"
						value={dayjs(license.endDate)}
						onChange={val => setLicenseValue('endDate', val)}
					/>
					<FormControlLabel
						label="Offline"
						control={
							<Checkbox
								checked={license.offline}
								onChange={evt => setLicenseValue('offline', evt.target.checked)}
							/>
						}
					/>
					<Collapse in={license.offline}>
						<Alert severity="info">
							An offline system will not be able to report usage back to the licensing server.
						</Alert>
					</Collapse>
				</StepContent>
				<StepContent step={2} activeStep={activeStep}>
					<Typography>Monthly Limits</Typography>
					<div style={{ margin: 24 }}>
						<Limit
							label="App Users"
							options={appUserSteps}
							setValue={val => setLimitValues('appUsers', val)}
							value={license.limits.appUsers}
							existingLimit={existingLicense?.limits.appUsers}
						/>
						<Limit
							label="Executions"
							options={executionSteps}
							setValue={val => setLimitValues('executions', val)}
							value={license.limits.executions}
							existingLimit={existingLicense?.limits.executions}
						/>

						<Limit
							label="Executions Seconds"
							caption="Hours will be converted to seconds"
							options={executionSecondsSteps}
							setValue={val => setLimitValues('executionSeconds', val)}
							value={license.limits.executionSeconds}
							valueTransform={(seconds: number) => seconds / 3600}
							otherTransform={(hours: number) => hours * 3600}
							existingLimit={existingLicense?.limits.executionSeconds}
						/>

						<Limit
							label="GUI Bots"
							options={guiBotSteps}
							setValue={val => setLimitValues('guiBots', val)}
							value={license.limits.guiBots}
							existingLimit={existingLicense?.limits.guiBots}
						/>
						<Limit
							label="Tolerance"
							options={toleranceSteps}
							setValue={val => setLimitValues('tolerance', val)}
							value={license.limits.tolerance}
							existingLimit={existingLicense?.limits.tolerance}
						/>
					</div>
				</StepContent>
				<NavigationButtons
					activeStep={activeStep}
					lastStep={2}
					setActiveStep={setActiveStep}
					submit={submit}
					disableNext={nextDisabled}
				/>
			</LocalizationProvider>
		</div>
	);
}

const useStepStyles = makeStyles()(theme => ({
	panel: {
		padding: theme.spacing(0, 2),
		width: '100%',
		height: '100%',
		transition: theme.transitions.create(['transform', 'opacity']),
		overflowY: 'auto',
	},
}));

function StepContent({
	activeStep,
	step,
	children,
}: PropsWithChildren<{ activeStep: number; step: number }>) {
	const active = useMemo(() => step === activeStep, [activeStep]);
	const { classes } = useStepStyles();
	return (
		<div className={classes.panel} style={{ opacity: active ? 1 : 0 }}>
			{active && children}
		</div>
	);
}

interface NavigationButtonsProps {
	activeStep: number;
	setActiveStep: React.Dispatch<React.SetStateAction<number>>;
	lastStep: number;
	submit(): void;
	disableNext: boolean;
}

function NavigationButtons({
	activeStep,
	setActiveStep,
	lastStep,
	submit,
	disableNext = false,
}: NavigationButtonsProps) {
	const isLastStep = useMemo(() => activeStep === lastStep, [activeStep]);
	return (
		<Box sx={{ display: 'flex', width: '100%', position: 'absolute', bottom: 0 }}>
			<div style={{ flex: 1 }}>
				<Button disabled={activeStep === 0} onClick={() => setActiveStep((s: number) => s - 1)}>
					Back
				</Button>
			</div>

			<Button
				onClick={() => {
					isLastStep ? submit() : setActiveStep((s: number) => s + 1);
				}}
				disabled={disableNext}
				variant={isLastStep ? 'contained' : 'text'}
			>
				{isLastStep ? 'Create License' : 'Next'}
			</Button>
		</Box>
	);
}

interface OptionSteps {
	value: number;
	label?: string;
}

interface LimitProps {
	label: string;
	options: OptionSteps[];
	value: number;
	setValue(val): void;
	caption?: string | undefined;
	valueTransform?: (val: number) => number;
	otherTransform?: (val: number) => number;
	existingLimit?: number;
}

function Limit({
	value,
	options,
	label,
	setValue,
	caption = '',
	otherTransform = val => val,
	valueTransform = val => val,
	existingLimit,
}: LimitProps) {
	const { classes } = useStyles();
	const isOther = useMemo(
		() => !options.some(x => x.value === value) && value !== -1,
		[value, options],
	);

	useEffect(() => {
		// If you are copying from an existing license then make sure that other is set if the value is other
		if (existingLimit !== undefined) {
			setOther(!options.some(x => x.value === existingLimit) && existingLimit !== -1);
		}
	}, [existingLimit]);

	const [other, setOther] = useState(isOther);
	return (
		<>
			<div className={classes.title}>
				<Typography variant="caption">{label}</Typography>
				{!!caption && <Typography sx={{ fontSize: '0.7rem' }}>{caption}</Typography>}
			</div>

			<div className={classes.sliders}>
				{options.map(step => (
					<Option
						key={`${label}_${step.value}`}
						value={step.value}
						label={step.label}
						selected={value === step.value}
						onClick={val => {
							setValue(val);
							setOther(false);
						}}
					/>
				))}
				<Option
					value={-1}
					label="∞"
					selected={value === -1}
					onClick={val => {
						setValue(val);
						setOther(false);
					}}
				/>
				{!other && (
					<Option
						value={0}
						label="Other"
						selected={other}
						onClick={val => {
							setValue(val);
							setOther(true);
						}}
					/>
				)}

				{other && (
					<div>
						<TextField
							autoFocus
							margin="dense"
							size="small"
							type="number"
							slotProps={{ htmlInput: { min: 0 } }}
							sx={{ width: 120, margin: 0, marginLeft: 1 }}
							disabled={!other}
							InputProps={{ className: classes.other }}
							value={valueTransform(value)}
							onChange={evt => setValue(otherTransform(Number(evt.target.value)))}
						/>
					</div>
				)}
			</div>
		</>
	);
}

interface OptionProps {
	value: number;
	label?: string;
	selected: boolean;
	onClick: (value: number) => void;
}

function Option({ label, value, selected, onClick }: OptionProps) {
	return (
		<Button
			variant={selected ? 'contained' : 'outlined'}
			color={selected ? 'primary' : 'secondary'}
			size="large"
			onClick={() => onClick(value)}
			sx={{ marginInline: [1, 1] }}
		>
			{label || value}
		</Button>
	);
}
