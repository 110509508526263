import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Environment } from '../../entities';
import { CreateEnvironment, UpdateEnvironment } from '../../store/environments';

export function useCreateEnvironment(customerId: string) {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [name, setName] = useState('');
	const [env, setEnv] = useState<Environment>();

	const createEnv = useCallback(async () => {
		if (customerId && !isNaN(parseInt(customerId, 10))) {
			dispatch(new CreateEnvironment(parseInt(customerId, 10), name));
			close();
		}
	}, [name, customerId]);

	const updateEnv = useCallback(() => {
		dispatch(new UpdateEnvironment({ ...env, name }));
		close();
	}, [name, env]);

	const submit = useCallback(() => {
		if (!!env) {
			updateEnv();
		} else {
			createEnv();
		}
	}, [env, name, customerId]);

	const close = () => {
		setName('');
		setOpen(false);
		setEnv(undefined);
	};

	return {
		open(e?: Environment) {
			if (!!e) {
				setEnv(e);
				setName(e.name);
			}
			setOpen(true);
		},
		render() {
			return (
				<Dialog open={open} onClose={close}>
					<DialogTitle>{!!env ? 'Update' : 'Create'} Environment</DialogTitle>
					<DialogContent>
						<TextField
							value={name}
							variant="outlined"
							margin="dense"
							onChange={evt => setName(evt.target.value)}
							fullWidth
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={close}>Cancel</Button>
						<Button onClick={submit}>{!!env ? 'Update' : 'Create'}</Button>
					</DialogActions>
				</Dialog>
			);
		},
	};
}
