import { ActionsObservable, combineEpics, ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { CustomerService } from '../../components/customer';
import { addSnackbar } from '../snackbar';
import {
	CreateCustomer,
	CreateCustomerFail,
	CreateCustomerSuccess,
	CustomerActions,
	CustomerActionTypes,
	DeleteCustomer,
	DeleteCustomerFail,
	DeleteCustomerSuccess,
	LoadCustomersFail,
	LoadCustomersSuccess,
	LoadSingleCustomer,
	LoadSingleCustomerSuccess,
	UpdateCustomer,
	UpdateCustomerFail,
	UpdateCustomerSuccess,
} from './customer.actions';

const loadCustomers$ = (action$: ActionsObservable<CustomerActions>) =>
	action$.pipe(
		ofType(CustomerActionTypes.LoadCustomers),
		switchMap(() =>
			CustomerService.getAll().then(
				definitions => new LoadCustomersSuccess(definitions),
				err => new LoadCustomersFail(err),
			),
		),
	);

const createCustomer$ = (action$: ActionsObservable<CreateCustomer>) =>
	action$.pipe(
		ofType<CreateCustomer>(CustomerActionTypes.CreateCustomer),
		switchMap(action =>
			CustomerService.create(action.name, action.orgId).then(
				def => new CreateCustomerSuccess(def),
				err => new CreateCustomerFail(err),
			),
		),
	);

const createCustomerFail$ = (action$: ActionsObservable<CreateCustomerFail>) =>
	action$.pipe(
		ofType<CreateCustomerFail>(CustomerActionTypes.CreateCustomerFail),
		switchMap(async () => addSnackbar({ variant: 'error', message: 'Failed to create customer' })),
	);

const updateCustomer$ = (action$: ActionsObservable<UpdateCustomer>) =>
	action$.pipe(
		ofType<UpdateCustomer>(CustomerActionTypes.UpdateCustomer),
		switchMap(action =>
			CustomerService.updateCustomer(action.customer).then(
				def => new UpdateCustomerSuccess(def),
				err => new UpdateCustomerFail(err),
			),
		),
	);

const updateCustomerFail$ = (action$: ActionsObservable<UpdateCustomerFail>) =>
	action$.pipe(
		ofType<UpdateCustomerFail>(CustomerActionTypes.UpdateCustomerFail),
		switchMap(async () => addSnackbar({ variant: 'error', message: 'Failed to update customer' })),
	);

const deleteCustomer$ = (action$: ActionsObservable<DeleteCustomer>) =>
	action$.pipe(
		ofType<DeleteCustomer>(CustomerActionTypes.DeleteCustomer),
		switchMap(action =>
			CustomerService.deleteCustomer(action.customerId).then(
				_ =>
					action.force
						? new DeleteCustomerSuccess(action.customerId)
						: new LoadSingleCustomer(action.customerId),
				err => new DeleteCustomerFail(err),
			),
		),
	);

const deleteCustomerFail$ = (action$: ActionsObservable<DeleteCustomerFail>) =>
	action$.pipe(
		ofType<DeleteCustomerFail>(CustomerActionTypes.DeleteCustomerFail),
		switchMap(async () => addSnackbar({ variant: 'error', message: 'Failed to delete customer' })),
	);

const loadSingleCustomer$ = (action$: ActionsObservable<LoadSingleCustomer>) =>
	action$.pipe(
		ofType<LoadSingleCustomer>(CustomerActionTypes.LoadSingleCustomer),
		switchMap(action =>
			CustomerService.get(action.customerId.toString()).then(
				def => new LoadSingleCustomerSuccess(def),
				err => new CreateCustomerFail(err),
			),
		),
	);
export const effects$ = combineEpics(
	loadCustomers$,
	createCustomer$,
	updateCustomer$,
	deleteCustomer$,
	createCustomerFail$,
	updateCustomerFail$,
	deleteCustomerFail$,
	loadSingleCustomer$,
);
