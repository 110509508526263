import { ActionsObservable, combineEpics, ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import { UserService } from '../../components/users';
import { addSnackbar } from '../snackbar';
import {
	CreateUser,
	CreateUserFail,
	CreateUserSuccess,
	DeleteUser,
	DeleteUserFail,
	DeleteUserSuccess,
	LoadUsers,
	LoadUsersFail,
	LoadUsersSuccess,
	UpdateUser,
	UpdateUserFail,
	UpdateUserSuccess,
	UserActions,
	UserActionTypes,
} from './user.actions';

const loadUsers$ = (action$: ActionsObservable<UserActions>) =>
	action$.pipe(
		ofType(UserActionTypes.LoadUsers),
		switchMap(() =>
			UserService.getAll().then(
				definitions => new LoadUsersSuccess(definitions),
				err => new LoadUsersFail(err),
			),
		),
	);

const createUser$ = (action$: ActionsObservable<CreateUser>) =>
	action$.pipe(
		ofType(UserActionTypes.CreateUser),
		switchMap(action =>
			UserService.create(action.user).then(
				u => new CreateUserSuccess(u),
				err => new CreateUserFail(err),
			),
		),
	);

const updateUser$ = (action$: ActionsObservable<UpdateUser>) =>
	action$.pipe(
		ofType(UserActionTypes.UpdateUser),
		switchMap(action =>
			UserService.update(action.user).then(
				u => new UpdateUserSuccess(u),
				err => new UpdateUserFail(err),
			),
		),
	);

const createFail$ = (action$: ActionsObservable<CreateUserFail>) =>
	action$.pipe(
		ofType(UserActionTypes.CreateUserFail),
		map(() => addSnackbar({ variant: 'error', message: 'Failed to create User.' })),
	);

const updateFail$ = (action$: ActionsObservable<UpdateUserFail>) =>
	action$.pipe(
		ofType(UserActionTypes.UpdateUserFail),
		map(() => addSnackbar({ variant: 'error', message: 'Failed to update User.' })),
	);

const deleteUser$ = (action$: ActionsObservable<DeleteUser>) =>
	action$.pipe(
		ofType(UserActionTypes.DeleteUser),
		switchMap(action =>
			UserService.deleteUser(action.userId).then(
				_ => new DeleteUserSuccess(action.userId),
				err => new DeleteUserFail(err),
			),
		),
	);

const deleteUserSuccess$ = (action$: ActionsObservable<DeleteUserSuccess>) =>
	action$.pipe(
		ofType(UserActionTypes.DeleteUserSuccess),
		map(() => new LoadUsers()),
	);

export const effects$ = combineEpics(
	loadUsers$,
	createUser$,
	deleteUser$,
	updateUser$,
	deleteUserSuccess$,
	createFail$,
	updateFail$,
);
