import { Action } from 'redux';
import { License } from '../../entities';
import { Clear } from '../clear';

export enum LicenseActionTypes {
	LoadLicences = '[License] Load Licenses',
	LoadLicencesSuccess = '[License] Load Licenses Success',
	LoadLicencesFail = '[License] Load Licenses Fail',

	LoadSingleLicense = '[License] Load single License',
	LoadSingleLicenseSuccess = '[License] Load single License Success',
	LoadSingleLicenseFail = '[License] Load single License Fail',

	CreateLicense = '[License] Create License',
	CreateLicenseSuccess = '[License] Create License Success',
	CreateLicenseFail = '[License] Create License Fail',

	DeleteLicense = '[License] Delete License',
	DeleteLicenseSuccess = '[License] Delete License Success',
	DeleteLicenseFail = '[License] Delete License Fail',
}

export class LoadLicences implements Action {
	public readonly type = LicenseActionTypes.LoadLicences;
	constructor(public showDisabled?: boolean) {}
}

export class LoadLicencesSuccess implements Action {
	public readonly type = LicenseActionTypes.LoadLicencesSuccess;
	constructor(public licences: License[]) {}
}

export class LoadLicencesFail implements Action {
	public readonly type = LicenseActionTypes.LoadLicencesFail;
	constructor(public error: any) {}
}

export class LoadSingleLicense implements Action {
	public readonly type = LicenseActionTypes.LoadSingleLicense;
	constructor(public licenseId: string) {}
}

export class LoadSingleLicenseSuccess implements Action {
	public readonly type = LicenseActionTypes.LoadSingleLicenseSuccess;
	constructor(public license: License) {}
}

export class LoadSingleLicenseFail implements Action {
	public readonly type = LicenseActionTypes.LoadSingleLicenseFail;
	constructor(public error: any) {}
}

export class CreateLicense implements Action {
	public readonly type = LicenseActionTypes.CreateLicense;
	constructor(public license: Partial<License>) {}
}

export class CreateLicenceSuccess implements Action {
	public readonly type = LicenseActionTypes.CreateLicenseSuccess;
	constructor(public licence?: License) {}
}

export class CreateLicenceFail implements Action {
	public readonly type = LicenseActionTypes.CreateLicenseFail;
	constructor(public error: any) {}
}

export class DeleteLicense implements Action {
	public readonly type = LicenseActionTypes.DeleteLicense;
	constructor(public licenseId: License['id']) {}
}

export class DeleteLicenceSuccess implements Action {
	public readonly type = LicenseActionTypes.DeleteLicenseSuccess;
	constructor(public licenseId: License['id']) {}
}

export class DeleteLicenceFail implements Action {
	public readonly type = LicenseActionTypes.DeleteLicenseFail;
	constructor(public error: any) {}
}

export type LicenseActions =
	| LoadLicences
	| LoadLicencesSuccess
	| LoadLicencesFail
	| LoadSingleLicense
	| LoadSingleLicenseSuccess
	| LoadSingleLicenseFail
	| CreateLicense
	| CreateLicenceSuccess
	| CreateLicenceFail
	| DeleteLicense
	| DeleteLicenceSuccess
	| DeleteLicenceFail
	| Clear;
