import { Dispatch, SetStateAction, useEffect, useState } from 'react';

/**
 * Save and load a value from local storage
 */
export function usePreference<T>(
	key: string,
	defaultValue: T,
	useSession: boolean = false,
): [T, Dispatch<SetStateAction<T>>] {
	const storage = useSession ? sessionStorage : localStorage;
	const [value, setValue] = useState<T>(() => {
		try {
			const v = storage.getItem(key);
			return v == null ? defaultValue : JSON.parse(v);
		} catch {
			return defaultValue;
		}
	});

	useEffect(() => {
		try {
			storage.setItem(key, JSON.stringify(value));
		} catch {}
	}, [value]);

	return [value, setValue];
}
