import { toPlainObject } from 'lodash';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import {
	CUSTOMER_FEAUTURE_KEY,
	effects$ as customerEffects$,
	reducer as customerReducer,
} from './customer';
import {
	ENVIRONMENT_FEAUTURE_KEY,
	effects$ as environmentEffects$,
	reducer as environmentReducer,
} from './environments';
import {
	LICENSE_FEAUTURE_KEY,
	effects$ as licenseEffects$,
	reducer as lisenceReducer,
} from './license';
import {
	ORGANIZATION_FEAUTURE_KEY,
	effects$ as organizationEffects$,
	reducer as organizationReducer,
} from './organization';
import { SNACKBAR_FEATURE_KEY, reducer as snackbarReducer } from './snackbar';
import { USER_FEAUTURE_KEY, effects$ as userEffects$, reducer as userReducer } from './user';

const epicMiddleware = createEpicMiddleware();

const reducer = combineReducers({
	[LICENSE_FEAUTURE_KEY]: lisenceReducer,
	[CUSTOMER_FEAUTURE_KEY]: customerReducer,
	[ORGANIZATION_FEAUTURE_KEY]: organizationReducer,
	[ENVIRONMENT_FEAUTURE_KEY]: environmentReducer,
	[USER_FEAUTURE_KEY]: userReducer,
	[SNACKBAR_FEATURE_KEY]: snackbarReducer,
});

export const store = createStore(
	reducer,
	applyMiddleware(
		() => next => action => {
			next(toPlainObject(action));
		},
		epicMiddleware,
	),
);

const effects$ = combineEpics(
	licenseEffects$,
	customerEffects$,
	organizationEffects$,
	environmentEffects$,
	userEffects$,
);

epicMiddleware.run(effects$);
