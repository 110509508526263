import {
	Alert,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Customer } from '../../entities';
import { DeleteCustomer } from '../../store/customer';
import { Spacer } from '../Spacer';

export function useDeleteCustomerDialog() {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [force, setForce] = useState(false);
	const [customer, setCustomer] = useState<Customer>();

	const close = () => {
		setOpen(false);
		setCustomer(undefined);
	};

	const deleteCustomer = useCallback(async () => {
		if (customer?.id) {
			dispatch(new DeleteCustomer(customer.id, force));
			close();
		}
	}, [customer, force]);

	return {
		open(u: Customer, f: boolean = false) {
			setOpen(true);
			setForce(f);
			setCustomer(u);
		},
		render() {
			const disableOrDelete = customer?.enabled ? 'Disable' : 'Delete';
			return (
				<Dialog open={open} onClose={() => setOpen(false)}>
					<DialogTitle>
						{disableOrDelete} Customer <code>'{customer?.name}'</code>
					</DialogTitle>
					<DialogContent>
						<Typography>
							You are about to {disableOrDelete.toLowerCase()} <code>'{customer?.name}'</code>. This{' '}
							{disableOrDelete.toLowerCase()}s the customer from the platform and they will no
							longer be able to have licenses created for them
						</Typography>
						{force && (
							<>
								<Spacer />
								<Alert severity="error">
									Deleting this customer will delete all environments and licenses associated with
									this customer
								</Alert>
							</>
						)}
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setOpen(false)}>Cancel</Button>
						<Button onClick={deleteCustomer}>{disableOrDelete}</Button>
					</DialogActions>
				</Dialog>
			);
		},
	};
}
