import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	TextField,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Organization } from '../../entities';
import { CreateOrganization, UpdateOrganization } from '../../store/organization';

interface OrganizationDialogProps {
	open: boolean;
	onClose(): void;
	org: Organization | undefined;
}

function OrganizationDialog({ open, onClose, org }: OrganizationDialogProps) {
	const dispatch = useDispatch();
	const [name, setName] = useState('');
	const [enabled, setEnabled] = useState(true);
	const isEditing = useMemo(() => !!org, [org]);

	const close = () => {
		setName('');
		onClose();
	};

	useEffect(() => {
		if (open && !!org) {
			setName(org.name);
			setEnabled(org.enabled);
		}
	}, [org]);

	const createOrg = useCallback(async () => {
		if (isEditing) {
			dispatch(new UpdateOrganization({ ...org, name, enabled }));
		} else {
			dispatch(new CreateOrganization(name));
		}
		onClose();
	}, [name, isEditing, org, enabled]);

	return (
		<Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
			<DialogTitle>{isEditing ? 'Update' : 'Create'} Organization</DialogTitle>
			<DialogContent>
				<TextField
					value={name}
					label="Name"
					onChange={evt => setName(evt.target.value)}
					variant="outlined"
					fullWidth
					margin="dense"
				/>
				{isEditing && (
					<FormControlLabel
						label="Enabled"
						control={
							<Checkbox checked={enabled} onChange={evt => setEnabled(evt.target.checked)} />
						}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={close} color="inherit">
					Cancel
				</Button>
				<Button onClick={createOrg}>{isEditing ? 'Update' : 'Create'}</Button>
			</DialogActions>
		</Dialog>
	);
}

export function useCreateOrgDialog() {
	const [open, setOpen] = useState(false);
	const [org, setOrg] = useState<Organization>();
	return {
		open(o?: Organization) {
			setOpen(true);
			if (o) {
				setOrg(o);
			}
		},
		render() {
			return <OrganizationDialog open={open} onClose={() => setOpen(false)} org={org} />;
		},
	};
}
