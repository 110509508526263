import { Action } from 'redux';
import { Snackbar } from './models';

export enum SnackbarActionTypes {
	Add = '[Snackbars] Add',
	Hide = '[Snackbars] Hide',
	Remove = '[Snackbars] Remove',
}

export class Add implements Action {
	public readonly type = SnackbarActionTypes.Add;
	constructor(public snackbar: Snackbar) {}
}

export class Hide implements Action {
	public readonly type = SnackbarActionTypes.Hide;
	constructor(public id: string) {}
}

export class Remove implements Action {
	public readonly type = SnackbarActionTypes.Remove;
	constructor(public id: string) {}
}

export type SnackbarActions = Add | Hide | Remove;

export function addSnackbar(snackbar: Partial<Snackbar>): SnackbarActions {
	const sb = { message: '', visible: true, onClose: () => {}, ...snackbar };
	return { type: SnackbarActionTypes.Add, snackbar: sb };
}

export function hideSnackbar(id: string): SnackbarActions {
	return { type: SnackbarActionTypes.Hide, id };
}

export function removeSnackbar(id: string): SnackbarActions {
	return { type: SnackbarActionTypes.Remove, id };
}
