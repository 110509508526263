import { Usage } from '../../entities';
import { env } from '../../enviroment';
import { getHeaders } from '../../utils';
import { queryBuilder } from '../../utils/queryBuilder';

export interface UsageQuery {
	customerId?: number;
	organizationId?: number;
	environmentId?: number;
	fromDateTime?: string;
	toDateTime?: string;
	offset?: number;
	yield?: number;
	usageGrouping?: 'Day' | 'Week' | 'Month' | 'Year' | 'Lifetime';
}

export namespace UsageService {
	export async function getUsage(usageQuery?: UsageQuery): Promise<Usage[]> {
		const query = usageQuery ? queryBuilder(usageQuery) : '';
		const res = await fetch(`${env.apiUrl}/api/1/Environment/Usage${!!query ? `?${query}` : ''}`, {
			headers: getHeaders({}),
		});
		return await res.json();
	}
}
