import { Customer } from '../../entities';
import { env } from '../../enviroment';
import { getHeaders } from '../../utils';

export namespace CustomerService {
	export async function getAll(orgId?: number): Promise<Customer[]> {
		const response = await fetch(
			`${env.apiUrl}/api/1/Customer?showDisabled=true${orgId ? `&OrganizationId=${orgId}` : ''}`,
			{ headers: getHeaders() },
		);
		return await response.json();
	}
	export async function get(id: string): Promise<Customer> {
		const response = await fetch(`${env.apiUrl}/api/1/Customer?showDisabled=true&id=${id}`, {
			headers: getHeaders(),
		});
		const json = await response.json();
		return json[0];
	}
	export async function create(name: string, organizationId: number): Promise<Customer> {
		const response = await fetch(`${env.apiUrl}/api/1/Customer`, {
			method: 'POST',
			headers: getHeaders({ 'Content-Type': 'application/json' }),
			body: JSON.stringify({
				name,
				organizationId,
				enabled: true,
			}),
		});
		if (!response.ok) throw response;
		return await response.json();
	}

	export async function updateCustomer(customer: Partial<Customer>): Promise<Customer> {
		const response = await fetch(`${env.apiUrl}/api/1/Customer`, {
			method: 'PATCH',
			headers: getHeaders({ 'Content-Type': 'application/json' }),
			body: JSON.stringify({
				...customer,
			}),
		});
		if (!response.ok) throw response;
		return await response.json();
	}

	export async function deleteCustomer(customerId: number): Promise<void> {
		const res = await fetch(`${env.apiUrl}/api/1/Customer/${customerId}`, {
			method: 'DELETE',
			headers: getHeaders({}),
		});
		if (!res.ok) throw res;
	}
}
