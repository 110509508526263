import { createEntityAdapter, EntityState } from 'redux-ngrx-entity';
import { Environment } from '../../entities';
import { EnvironmentActions, EnvironmentActionTypes } from './environment.actions';

export const ENVIRONMENT_FEAUTURE_KEY = 'environment';

export interface EnvironmentState extends EntityState<Environment> {
	loaded: boolean;
	loadedPerCustomer: Record<number, boolean>;
}

export const environmentAdapter = createEntityAdapter<Environment>({
	selectId: entity => entity.id ?? 0,
});

const initialState: EnvironmentState = environmentAdapter.getInitialState({
	loaded: false,
	loadedPerCustomer: {},
});

export function reducer(
	state: EnvironmentState = initialState,
	action: EnvironmentActions,
): EnvironmentState {
	switch (action.type) {
		case EnvironmentActionTypes.LoadEnvironmentsSuccess: {
			return environmentAdapter.addMany(action.environments, {
				...state,
				loaded: true,
				loadedPerCustomer: {
					...state.loadedPerCustomer,
					[action.customerId]: true,
				},
			});
		}
		case EnvironmentActionTypes.LoadEnvironmentsFail: {
			return { ...state, loaded: true };
		}
		case EnvironmentActionTypes.CreateEnvironmentSuccess: {
			return environmentAdapter.addOne(action.environment, { ...state });
		}

		case EnvironmentActionTypes.DeleteEnvironmentSuccess: {
			return environmentAdapter.removeOne(action.environmentId, state);
		}
		case EnvironmentActionTypes.LoadSingleEnvironmentSuccess: {
			return environmentAdapter.upsertOne(action.environment, state);
		}
		case EnvironmentActionTypes.UpdateEnvironmentSuccess: {
			return environmentAdapter.upsertOne(action.environment, state);
		}
		case EnvironmentActionTypes.ClearLoadedEnvironments:
		case '@toca/clear': {
			return environmentAdapter.removeAll({ ...state, loaded: false, loadedPerCustomer: {} });
		}
	}

	return state;
}
