import { useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { env } from '../enviroment';
import { getAccessToken, getClaims, getHeaders, getTokenLifetime, isTokenExpired } from '../utils';
import { useRole } from './RoleProvider';

export const nonAuthedPages = ['reset', 'forgot', 'login'];

export function AuthCheck() {
	const roleSet = useRef(false);
	const { setRole } = useRole();
	const location = useLocation();
	const nav = useNavigate();
	useEffect(() => {
		const token = getAccessToken();
		if (!token || (token && isTokenExpired(token))) {
			if (!nonAuthedPages.some(x => location.pathname.includes(x))) {
				nav('/login');
			}
		}
	}, [location.pathname]);

	const reissueToken = useCallback(async () => {
		const response = await fetch(`${env.apiUrl}/api/1/Authentication/Reissue`, {
			headers: getHeaders({}),
		});
		const newToken = await response.json();
		localStorage.setItem('access_token', newToken as any);
	}, []);

	useEffect(() => {
		const token = getAccessToken();
		if (!token || isTokenExpired(token)) {
			return;
		}
		const claims = getClaims(token);
		const expiresIn = claims.exp.getTime() - Date.now();
		const lifetime = getTokenLifetime(token);
		const halfLife = lifetime / 2;

		if (expiresIn < halfLife) {
			reissueToken();
			return;
		}

		const tmId = setTimeout(reissueToken, expiresIn / 2);
		return () => clearTimeout(tmId);
	}, []);

	useEffect(() => {
		const token = getAccessToken();
		if (token && !roleSet.current) {
			roleSet.current = true;
			const claims = getClaims(token);
			setRole(claims.role);
		}
		if ((location.pathname = '/login')) {
			roleSet.current = false;
		}
	}, [location]);
	return <></>;
}
