import { createSelector } from 'reselect';
import { createFeatureSelector } from '../../utils/createFeatureSelctor';
import { CUSTOMER_FEAUTURE_KEY, customerAdapter, CustomerState } from './customer.reducer';

const getCustomerFeatureState = createFeatureSelector<CustomerState>(CUSTOMER_FEAUTURE_KEY);

const { selectAll, selectEntities } = customerAdapter.getSelectors();

export const getAllCustomers = createSelector(getCustomerFeatureState, selectAll);
export const getCustomerEntities = createSelector(getCustomerFeatureState, selectEntities);

export const getCustomersLoaded = createSelector(getCustomerFeatureState, state => state.loaded);
export const getCustomerById = (id: number) =>
	createSelector(getAllCustomers, entities => entities.find(x => x.id === id));
