import { useMediaQuery } from '@mui/material';
import { blueGrey, grey, pink } from '@mui/material/colors';
import { createTheme, responsiveFontSizes, Theme, ThemeProvider } from '@mui/material/styles';
import {
	createContext,
	PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { usePreference } from './usePreference';

export interface Context {
	theme: Theme;
	setDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const AppThemeContext = createContext<Context | undefined>(undefined);

const customBreakpoints = {
	values: {
		xs: 0,
		sm: 600,
		md: 900,
		lg: 1200,
		xl: 1536,
		widescreen: 2018,
	},
};

export function AppThemeProvider({ children }: PropsWithChildren<{}>) {
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
	const loadedRef = useRef<boolean>(false);
	const [darkMode, setDarkMode] = usePreference('prefersDarkMode', prefersDarkMode);
	const [theme, setTheme] = useState<Theme>(
		responsiveFontSizes(
			createTheme({
				breakpoints: {
					...customBreakpoints,
				},
				palette: {
					primary: pink,
					secondary: blueGrey,
					background: {
						...(darkMode ? { default: grey[900] } : {}),
					},
					mode: darkMode ? 'dark' : 'light',
				},
				typography: {
					fontFamily: "'Montserrat', sans-serif",
				},
			}),
		),
	);

	useEffect(() => {
		setTheme(
			responsiveFontSizes(
				createTheme({
					breakpoints: {
						...customBreakpoints,
					},
					palette: {
						primary: pink,
						secondary: blueGrey,
						background: {
							...(darkMode ? { default: grey[900] } : {}),
						},
						mode: darkMode ? 'dark' : 'light',
					},
					typography: {
						fontFamily: "'Montserrat', sans-serif",
					},
				}),
			),
		);
	}, [darkMode]);

	const value = useMemo(() => ({ theme, setDarkMode }), [theme]);

	return (
		<ThemeProvider theme={theme}>
			<AppThemeContext.Provider value={value}>{children}</AppThemeContext.Provider>
		</ThemeProvider>
	);
}

export function useAppTheme() {
	const context = useContext(AppThemeContext);
	if (!context) {
		throw new Error('No App Theme Provider');
	}
	const { setDarkMode } = context;
	const toggleMode = useCallback(() => {
		setDarkMode(d => !d);
	}, [setDarkMode]);
	return {
		toggleMode,
	};
}
