import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridPaginationModel } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllOrgs, getOrganizationLoaded, LoadOrganizations } from '../../store/organization';
import { useHomeStyles } from '../../utils';
import { useRole } from '../RoleProvider';
import { Spacer } from '../Spacer';
import { useCreateOrgDialog } from './create';
import { useDeleteOrgDialog } from './delete';

export function OrganizationHome() {
	const { isAdmin } = useRole();
	const nav = useNavigate();
	const location = useLocation();
	const { classes } = useHomeStyles();
	const dispatch = useDispatch();
	const orgs = useSelector(getAllOrgs);
	const orgsLoaded = useSelector(getOrganizationLoaded);
	useEffect(() => {
		if (!orgsLoaded && isAdmin) {
			dispatch(new LoadOrganizations());
		}
	}, [orgsLoaded, isAdmin]);

	const orgDialog = useCreateOrgDialog();
	const deleteOrgDialog = useDeleteOrgDialog();
	const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
		pageSize: location.pathname === '/' ? 5 : 10,
		page: 0,
	});

	return (
		<>
			<Paper className={classes.container}>
				<div className={classes.title}>
					<Typography variant="h5" sx={{ flex: 1 }}>
						Organizations
					</Typography>
					<div className={classes.title}>
						{location.pathname === '/' && (
							<>
								<Button variant="contained" onClick={() => nav('/organization')}>
									Organizations
								</Button>
								<Spacer />
							</>
						)}
						<Button variant="contained" onClick={() => orgDialog.open()}>
							{' '}
							<AddIcon />
							Create a new Organization
						</Button>
					</div>
				</div>
				<Spacer spacing={2} />
				<div>
					<DataGrid
						loading={!orgsLoaded}
						pageSizeOptions={[5, 10, 15, 25, 50]}
						paginationModel={paginationModel}
						onPaginationModelChange={setPaginationModel}
						disableRowSelectionOnClick
						autoHeight
						columns={[
							{
								field: 'id',
								headerName: 'ID',
								editable: false,
							},
							{
								field: 'name',
								headerName: 'Name',
								editable: false,
								width: 200,
							},
							{
								field: 'enabled',
								headerName: 'Enabled',
								editable: false,
								flex: 1,
								renderCell(params) {
									return params.row.enabled ? (
										<CheckIcon className={classes.icon} />
									) : (
										<CloseIcon className={classes.icon} />
									);
								},
							},
							{
								field: '',
								headerName: 'Actions',
								headerAlign: 'right',
								hideable: false,
								renderCell(params) {
									return (
										<>
											<Tooltip title="Edit">
												<IconButton
													onClick={evt => {
														evt.stopPropagation();
														orgDialog.open(params.row);
													}}
												>
													<EditIcon />
												</IconButton>
											</Tooltip>
											<Tooltip title={params.row.enabled ? 'Disable' : 'Delete'}>
												<IconButton
													disabled={!params.row.enabled && !isAdmin}
													onClick={evt => {
														evt.stopPropagation();
														if (isAdmin) {
															deleteOrgDialog.open(params.row, !params.row.enabled && isAdmin);
														}
													}}
												>
													<DeleteIcon />
												</IconButton>
											</Tooltip>
										</>
									);
								},
							},
						]}
						rows={orgs}
					/>
				</div>
			</Paper>
			{orgDialog.render()}
			{deleteOrgDialog.render()}
		</>
	);
}

export default OrganizationHome;
